import React, { BaseSyntheticEvent } from "react";

import clsx from "clsx";
import { Button, Card, Col, Divider, Row, Space, Tooltip } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Entry } from "contentful";
import { useNavigate } from "react-router-dom";

import { useMatomo } from "@jonkoops/matomo-tracker-react";
import moment from "moment";
import getRelativeFromAbsoluteUrl from "../../../../utils/getRelativeFromAbsoluteUrl";
import downloadContenfulFile from "../../../../utils/downloadContenfulFile";
import isExternalUrl from "../../../../utils/isExternalUrl";
import { getTargetFromAsset, getTooltipTextFromAsset } from "../utils";
import {
  ContentfulImpulsaktionEntry,
  ContentfulImpulsaktionMainTeaserEntry,
} from "../../../../types/contentfulImpulsaktion";
import { ReactComponent as OrderIcon } from "../../../../static/svg/my-order.svg";
import { ReactComponent as VKFIcon } from "../../../../static/svg/vkf.svg";

interface ImpulseMainTeaserProps {
  impulsaktion: Entry<ContentfulImpulsaktionEntry>;
  className?: string;
  variant?: "small";
  colProps?: object;
}

const VKF_BUTTON_CTA = "Übersicht der VKF-Materialien";

const ImpulseMainTeaser: React.FC<ImpulseMainTeaserProps> = (
  props: ImpulseMainTeaserProps
) => {
  const {
    impulsaktion,
    className,
    variant = "",
    colProps = { xs: 12, lg: 6 },
  } = props;

  const navigate = useNavigate();

  const { trackEvent } = useMatomo();

  const { main_teaser: mainTeasers = [] } = impulsaktion?.fields || {};

  if (!Array.isArray(mainTeasers) || mainTeasers?.length < 1) {
    return null;
  }

  return (
    <div
      className={clsx(
        "impulse-main-teaser",
        variant && `impulse-main-teaser--${variant}`,
        className
      )}
    >
      <Row gutter={[40, 30]}>
        {mainTeasers?.map(
          (teaser: Entry<ContentfulImpulsaktionMainTeaserEntry>, i: number) => {
            const isExternalLink = isExternalUrl(teaser?.fields?.link);

            return (
              <Col
                key={`impulse-main-teaser_${teaser?.sys?.id}_${i}`}
                {...colProps}
              >
                <Card
                  title={teaser?.fields?.headline}
                  cover={
                    <img
                      src={teaser?.fields?.image?.fields?.file?.url}
                      alt={teaser?.fields?.headline}
                    />
                  }
                >
                  {variant !== "small" && !!teaser?.fields?.description && (
                    <p className="impulse-main-teaser__description">
                      {teaser?.fields?.description}
                    </p>
                  )}
                  {variant === "small" && <Divider />}
                  <Space>
                    {!!teaser?.fields?.link && !isExternalLink && (
                      <Button
                        className="button buttonSecondary width-full"
                        icon={<OrderIcon />}
                        onClick={() => {
                          trackEvent({
                            category: "download",
                            action: `${
                              impulsaktion?.fields?.name
                            } - ${moment().format("YYYY")}`,
                            name: `MainTeaser - ${teaser?.fields?.headline} - ${teaser?.fields?.link_text}`,
                          });

                          navigate(
                            getRelativeFromAbsoluteUrl(teaser?.fields?.link)
                          );
                        }}
                      >
                        {teaser?.fields?.link_text || ""}
                      </Button>
                    )}

                    {!!teaser?.fields?.link && isExternalLink && (
                      <Button
                        className="button buttonSecondary width-full"
                        icon={<LinkOutlined />}
                        href={teaser?.fields?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          trackEvent({
                            category: "download",
                            action: `${
                              impulsaktion?.fields?.name
                            } - ${moment().format("YYYY")}`,
                            name: `MainTeaser - ${teaser?.fields?.headline} - ${teaser?.fields?.link_text}`,
                          });
                        }}
                      >
                        {teaser?.fields?.link_text || ""}
                      </Button>
                    )}

                    {!!teaser.fields?.vkf_file?.fields?.file?.url && (
                      <Tooltip
                        title={getTooltipTextFromAsset(teaser.fields?.vkf_file)}
                      >
                        <Button
                          className="button buttonPrimary width-full"
                          icon={<VKFIcon />}
                          onClick={(e: BaseSyntheticEvent) => {
                            trackEvent({
                              category: "download",
                              action: `${
                                impulsaktion?.fields?.name
                              } - ${moment().format("YYYY")}`,
                              name: `MainTeaser - ${teaser?.fields?.headline} - ${VKF_BUTTON_CTA}`,
                            });

                            downloadContenfulFile(
                              e,
                              teaser.fields?.vkf_file,
                              getTargetFromAsset(teaser.fields?.vkf_file)
                            );
                          }}
                        >
                          {VKF_BUTTON_CTA}
                        </Button>
                      </Tooltip>
                    )}
                  </Space>
                </Card>
              </Col>
            );
          }
        )}
      </Row>
    </div>
  );
};

export default ImpulseMainTeaser;
