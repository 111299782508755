import React, { useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Spin } from "antd";
import axios, { CancelTokenSource } from "axios";

import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../../api/getCancelTokenSource";
import getProductsData from "../../../../api/products/getProductsData";
import QuickOrderItem from "./QuickOrderItem";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import { QuickOrderStateItem } from "../../../../types/quickOrder";
import { RootState } from "../../../../types/rootState";

interface QuickOrderItemsProps {
  className?: string;
}

const SKU_DIVIDER = "+";

const QuickOrderItems: React.FC<QuickOrderItemsProps> = (
  props: QuickOrderItemsProps
) => {
  const { className } = props;

  const dispatch = useDispatch();

  const isMounted = useRef<boolean>(false);
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  const itemsRequestString = useRef<string>("");

  const { deliveryDate } = useSelector(
    (state: RootState) => state.currentCartMetaData
  );
  const { items = [] } = useSelector((state: RootState) => state?.quickOrder);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateProductsData = useCallback(() => {
    if (!itemsRequestString.current) {
      return;
    }

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    setIsLoading(true);

    getProductsData({
      page: 1,
      categoryKey: "1",
      pageSize: itemsRequestString.current.split(SKU_DIVIDER).length,
      cancelTokenSource: cancelTokenSource.current,
      deliveryDates: deliveryDate,
      sku: itemsRequestString.current,
    })
      .then((response) => {
        const products = (response as any)?.products || [];
        dispatch({
          type: "quick-order/update-all-items",
          payload: { products },
        });
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
        }
        requestCatchHandler(error);
      });
  }, [deliveryDate, dispatch]);

  useEffect(() => {
    itemsRequestString.current = items
      .map((item: QuickOrderStateItem) => item.product.sku)
      .join(SKU_DIVIDER);
  }, [items]);

  useEffect(() => {
    updateProductsData();
  }, [deliveryDate, updateProductsData]);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    updateProductsData();
    isMounted.current = true;
  }, [updateProductsData]);

  if (!Array.isArray(items)) {
    return null;
  }

  return (
    <div className={clsx("quick-order-items", className)}>
      {isLoading && <Spin tip="Lade Artikel ..." />}

      {!isLoading &&
        !!items.length &&
        items.map((item: QuickOrderStateItem) => (
          <QuickOrderItem
            key={`quick-order-item_${item?.product?.sku || ""}`}
            deliveryDate={deliveryDate}
            item={item}
          />
        ))}

      {!isLoading && !items.length && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <>
              <div>Aktuell ist Ihre Schnellbestellung noch leer.</div>
              <div>
                Bitte nutzen Sie die Eingabemaske oben, um Artikel zu Ihrer
                Schnellbestellung hinzuzufügen.
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

export default QuickOrderItems;
