import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";
import { Button, message, Modal } from "antd";
import axios, { CancelTokenSource } from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import deleteCart from "../../api/cart/deleteCart";
import setLocalCart from "../../state/actions/setLocalCart";
import { messageData, routePathNames } from "../../appConfig";
import { ReactComponent as DeleteIcon } from "../../static/svg/delete.svg";

interface CartDeleteModalProps {
  itemCount: number;
  onClick: () => void;
}

const CartDeleteModal: React.FC<CartDeleteModalProps> = (
  props: CartDeleteModalProps
) => {
  const { itemCount = 0, onClick } = props;

  const navigate = useNavigate();

  const { id: cartId, deliveryDate } = useSelector(
    (state: any) => state.currentCartMetaData
  );

  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // store token in reference to persist it over the lifecycles
  const cancelTokenSource = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );

  const showModal = () => setIsVisible(true);
  const hideModal = () => setIsVisible(false);

  /**
   * DELETE spryker cart
   * @returns {Promise<AxiosResponse>}
   */
  const deleteCartFunction = async () => {
    setIsLoading(true);

    /**
     * delete cart
     * then make sure a new cart is fetched and set to redux
     * then close modal, show message
     * then return to shop page
     * if an error occurs
     * then show a message and fetch the cart for the date again
     * then return to shop page
     */
    return deleteCart(cartId, cancelTokenSource.current)
      .then(() =>
        setLocalCart({
          deliveryDate,
          cancelTokenSource: cancelTokenSource.current,
        })
      )
      .then(() => {
        setIsLoading(false);
        setIsVisible(false);

        return message.success(messageData.success.cart.deleteCart);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsVisible(false);

        if (!axios.isCancel(error)) {
          // show an other message if the cart didn't exist
          const messageContent =
            error?.response?.status === 404
              ? messageData.error.cart.notFound
              : messageData.error.cart.deleteCart;

          message.error(messageContent);

          return setLocalCart({
            deliveryDate,
            cancelTokenSource: cancelTokenSource.current,
          });
        }

        return Promise.resolve();
      })
      .then(() => {
        navigate(routePathNames.shop);
      });
  };

  return (
    <>
      <Button
        type="text"
        className="button buttonText buttonWithIcon buttonTextDecoration--inverted buttonCartDelete cart__menu-button"
        icon={<DeleteIcon className="icon" />}
        onClick={() => {
          showModal();
          if (typeof onClick === "function") {
            onClick();
          }
        }}
      >
        Warenkorb löschen
      </Button>

      <Modal
        title="ACHTUNG!"
        className="cartDeleteModal"
        width={530}
        keyboard
        closable
        maskClosable={false}
        confirmLoading={isLoading}
        visible={isVisible}
        cancelText="Abbrechen"
        onCancel={hideModal}
        okText="Ja, löschen"
        onOk={deleteCartFunction}
      >
        Möchtest du den Warenkorb für den Liefertag am{" "}
        {moment(deliveryDate).format("L")} wirklich löschen? Es{" "}
        {itemCount === 1 ? "befindet" : "befinden"} sich aktuell {itemCount}{" "}
        Artikel im Warenkorb.
      </Modal>
    </>
  );
};

export default CartDeleteModal;
