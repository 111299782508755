import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Popover } from "antd";
import clsx from "clsx";
import { ReactComponent as WeilingLogo } from "../../static/svg/weiling.svg";
import { ReactComponent as BurgerMenu } from "../../static/svg/burger-menu.svg";
import { ReactComponent as Cross } from "../../static/svg/close.svg";
import { ReactComponent as UserIcon } from "../../static/svg/account.svg";
import { ReactComponent as Phone } from "../../static/svg/phone.svg";

import SearchInput from "../searchInput/SearchInput";
import FoldoutMenu from "./FoldoutMenu";
import CartLinkButton from "../cart/CartLinkButton";
import UserNavigation from "../molecules/UserNavigation";
import ContactPerson from "../contactPerson/ContactPerson";
import DeliveryDate from "../deliveryDate/DeliveryDate";
import BusinessUnitBox from "../atoms/BusinessUnitBox";
import useGetContactPerson from "../../hooks/useGetContactPerson";
import HrDivider from "../divider/Divider";
import { ExpertAdviceNavLink } from "../atoms";
import { PricesSwitch } from "../molecules";

function Header() {
  const contactPerson = useGetContactPerson();

  const [menu, setMenu] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isContactPersonVisible, setIsContactPersonVisible] = useState(false);

  // todo refactor
  const handleMenu = () => {
    setMenu(!menu);

    const body = document.querySelector(".App");
    const search = document.querySelector(".header__search-mobile");
    const cart = document.querySelector(".header__cart");
    const mainnav = document.querySelector(".main-nav");

    if (search && cart && mainnav) {
      if (!menu) {
        body.classList.add("noscroll");
        search.classList.add("header__search-mobile-invisible");
        cart.classList.add("header__cart-mobile-invisible");
        mainnav.classList.add("main-nav-mobile-invisible");
      } else {
        body.classList.remove("noscroll");
        search.classList.remove("header__search-mobile-invisible");
        cart.classList.remove("header__cart-mobile-invisible");
        mainnav.classList.remove("main-nav-mobile-invisible");
      }
    }
  };

  return (
    <Layout.Header
      className="container-layout container-layout--header header header__sticky hidden-print"
      id="headerLayout"
    >
      <div className="header__wrapper">
        <Layout className="container-layout container-layout--inner">
          <div className="headerInner">
            <div className="headerMenu">
              {menu ? (
                <Cross className="burger-menu close-menu" />
              ) : (
                <div
                  role="button"
                  className="burger-menu-div"
                  onClick={handleMenu}
                  onKeyDown={handleMenu}
                  tabIndex={-1}
                >
                  <BurgerMenu className="burger-menu" />
                </div>
              )}
            </div>

            <Link to="/" className="header__logo-container">
              <WeilingLogo className="main-logo" />
            </Link>

            <SearchInput className="search__container" />

            <DeliveryDate className="date__container" />
            <BusinessUnitBox className="business-unit-box__container" />

            <PricesSwitch className="hidden-xs-down" />
            {!!contactPerson && (
              <div className="hidden-xs-down contactPersonComponent">
                <Popover
                  placement="topRight"
                  overlayClassName="ant-popover-placement-bottomRight hidden-xs-down"
                  content={
                    <>
                      <ContactPerson
                        contactPerson={contactPerson}
                        setIsContactPersonVisible={setIsContactPersonVisible}
                      />
                      <HrDivider className="divider" size={1} spacingTop="xs" />
                      <ExpertAdviceNavLink />
                    </>
                  }
                  trigger="click"
                  visible={isContactPersonVisible}
                  onVisibleChange={() =>
                    setIsContactPersonVisible(!isContactPersonVisible)
                  }
                >
                  <Phone
                    className={clsx(
                      isContactPersonVisible && "contactPersonIcon--open",
                      "contactPersonIcon"
                    )}
                  />
                </Popover>
              </div>
            )}
            <div className="header__user-navigation-container hidden-xs-down">
              <div className="burger-user">
                <Popover
                  placement="topRight"
                  overlayClassName="user-navigation-popover"
                  destroyTooltipOnHide
                  content={
                    <UserNavigation
                      onListItemClick={() => setIsNavVisible(false)}
                    />
                  }
                  trigger="click"
                  visible={isNavVisible}
                  onVisibleChange={() => setIsNavVisible(!isNavVisible)}
                >
                  <UserIcon
                    className={clsx(
                      isNavVisible && "header__user-navigation--open"
                    )}
                  />
                </Popover>
              </div>
            </div>

            <div className="header__cart-wrapper">
              <CartLinkButton />
            </div>
          </div>

          {!!menu && <FoldoutMenu onClick={handleMenu} />}
        </Layout>
      </div>
    </Layout.Header>
  );
}

export default Header;
