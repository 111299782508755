import React, { useRef, useState } from "react";

import clsx from "clsx";
import { useForm } from "antd/lib/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, InputRef, message, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios, { CancelTokenSource } from "axios";
import moment from "moment/moment";

import getProductByEan from "../../../../api/scanner/getProductByEan";
import getProductDetail from "../../../../api/products/getProductDetail";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../../api/getCancelTokenSource";
import useMedia from "../../../../hooks/useMedia";
import getCssVariable from "../../../../utils/getCssVariable";
import useCancelAxiosOnUnmount from "../../../../hooks/useCancelAxiosOnUnmount";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import getDeliveryDateBasedAttributes from "../../../product/getDeliveryDateBasedAttributes";
import QuickOrderItem from "../QuickOrderItems/QuickOrderItem";
import { messageData } from "../../../../appConfig";
import { ProductData } from "../../../../types/productData";
import { RootState } from "../../../../types/rootState";
import { QuickOrderStateItem } from "../../../../types/quickOrder";

interface QuickOrderFormProps {
  deliveryDate: string;
  product: ProductData;
  setProduct: (product: ProductData) => void;
  isSearching: boolean;
  setIsSearching: (isSearching: boolean) => void;
  className?: string;
}

const MAX_ITEMS = 100;

const QuickOrderForm: React.FC<QuickOrderFormProps> = (
  props: QuickOrderFormProps
) => {
  const {
    deliveryDate,
    product = null,
    setProduct = () => {},
    isSearching = false,
    setIsSearching = () => {},
    className,
  } = props;

  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-xl")})`
  );
  const [form] = useForm();
  const dispatch = useDispatch();

  const { items = [] } = useSelector((state: RootState) => state.quickOrder);

  const [isUnavailable, setIsUnavailable] = useState<boolean>(false);

  const skuInputRef = useRef<InputRef>(null);
  const quantityInputRef = useRef<InputRef>(null);
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const searchProduct = async () => {
    const query = form.getFieldValue("sku")?.trim();

    if (!query) {
      return;
    }

    const isEanSearch = query?.length > 8;

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    let products = [];

    setIsSearching(true);

    try {
      products = isEanSearch
        ? await getProductByEan(query, deliveryDate, cancelTokenSource.current)
        : await getProductDetail({
            productSku: query,
            deliveryDate,
            cancelTokenSource: cancelTokenSource.current,
          });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsSearching(false);
        message.error(messageData.error.cart.addItem);
      }
      requestCatchHandler(error);
      return;
    }

    setIsSearching(false);

    const concreteProduct = isEanSearch
      ? products?.[0]
      : products?.concreteProducts?.[0];

    if (!concreteProduct) {
      message.info(messageData.info.quickOrder.notFoundItem);
      setTimeout(() => skuInputRef?.current?.focus(), 100);
      return;
    }

    setProduct(concreteProduct);

    const { availabilities } = concreteProduct;

    const { isUnavailable: isProductUnavailable } =
      getDeliveryDateBasedAttributes({
        deliveryDate,
        availabilities,
      });

    setIsUnavailable(isProductUnavailable);

    if (isProductUnavailable) {
      setTimeout(() => skuInputRef?.current?.select(), 100);
    } else {
      setTimeout(() => quantityInputRef?.current?.select(), 100);
    }
  };

  const addItemToQuickOrderItems = () => {
    if (
      items?.length >= MAX_ITEMS &&
      items.findIndex(
        (item: QuickOrderStateItem) => item?.product?.sku === product?.sku
      ) === -1
    ) {
      message.info(messageData.info.quickOrder.maxItems);
      return;
    }

    dispatch({
      type: "quick-order/add-item",
      payload: {
        item: {
          quantity: isUnavailable
            ? 0
            : Number(form.getFieldValue("quantity") || 1),
          product,
          addedAt: moment(),
        },
      },
    });
    setProduct(null);
    form.resetFields();
    setTimeout(() => skuInputRef?.current?.focus(), 100);
  };

  return (
    <div className={clsx("quick-order-form", className)}>
      <Form
        form={form}
        initialValues={{ sku: "", quantity: "1" }}
        layout={browserIsDesktop ? "inline" : "vertical"}
        onFinish={addItemToQuickOrderItems}
        autoComplete="off"
      >
        <Form.Item
          label="Artikelnr. / EAN"
          name="sku"
          pattern="[0-9]*"
          rules={[{ required: true }, { min: 5 }, { max: 13 }]}
          className="quick-order-form__sku"
        >
          <Input
            disabled={isSearching}
            ref={skuInputRef}
            maxLength={13}
            allowClear
            suffix={isSearching && <Spin size="small" />}
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={() => {
              setProduct(null);
            }}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.code === "Escape") {
                setProduct(null);
                form.setFieldValue("sku", "");
              }
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.code === "Tab") {
                setProduct(null);
                searchProduct();
              }
            }}
            onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
              e.preventDefault();
              setProduct(null);
              searchProduct();
            }}
            addonAfter={
              <Button
                icon={<SearchOutlined />}
                onClick={() => {
                  setProduct(null);
                  searchProduct();
                }}
              />
            }
          />
        </Form.Item>
        <Form.Item
          label="Menge"
          name="quantity"
          pattern="[0-9]*"
          rules={[{ required: true }, { min: 1 }, { max: 3 }]}
          className="quick-order-form__quantity"
        >
          <Input
            disabled={isSearching || !product || isUnavailable}
            ref={quantityInputRef}
            maxLength={3}
            addonAfter="Stück"
            inputMode="numeric"
            pattern="[0-9]*"
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.code === "Escape") {
                form.setFieldValue("quantity", "");
              }
            }}
            onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
              e.preventDefault();
              form.submit();
            }}
          />
        </Form.Item>
        <Form.Item label="">
          <Button
            htmlType="submit"
            className="button buttonPrimary"
            disabled={isSearching || !product || isUnavailable}
          >
            Hinzufügen
          </Button>
        </Form.Item>
      </Form>
      {!!product?.sku && (
        <div className="quick-order-form__result">
          <div>Vorschau</div>
          <QuickOrderItem
            deliveryDate={deliveryDate}
            item={{ quantity: 0, product, addedAt: moment() }}
            isPreview
          />
        </div>
      )}
    </div>
  );
};

export default QuickOrderForm;
