import React, { useRef, useState } from "react";

import clsx from "clsx";
import { Affix, Button, message, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";

import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../../api/getCancelTokenSource";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import updateCartItemQuantityMulti from "../../../../state/actions/updateCartItemQuantityMulti";
import { messageData } from "../../../../appConfig";
import { QuickOrderStateItem } from "../../../../types/quickOrder";
import { RootState } from "../../../../types/rootState";

interface QuickOrderActionsProps {
  isDisabled?: boolean;
  className?: string;
}

const QuickOrderActions: React.FC<QuickOrderActionsProps> = (
  props: QuickOrderActionsProps
) => {
  const { isDisabled, className } = props;

  const dispatch = useDispatch();

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());

  const { deliveryDate } = useSelector(
    (state: RootState) => state.currentCartMetaData
  );
  const { items = [] } = useSelector((state: RootState) => state?.quickOrder);

  const [isAdding, setIsAdding] = useState<boolean>(false);

  const shouldDisableButtons = isDisabled || !items?.length || isAdding;

  const deleteAllItemsFromQuickOrderItems = () => {
    dispatch({
      type: "quick-order/delete-all-items",
    });
  };

  const updateCart = async () => {
    if (!Array.isArray(items) || !items?.length) {
      return;
    }

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    setIsAdding(true);

    try {
      await updateCartItemQuantityMulti({
        deliveryDate,
        items: items
          .map((item: QuickOrderStateItem) => {
            return {
              sku: item.product.sku,
              quantity: item.quantity,
            };
          })
          .filter((item: any) => (item?.quantity || 0) > 0),
        cancelTokenSource: cancelTokenSource.current,
      });
      deleteAllItemsFromQuickOrderItems();
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsAdding(false);
        message.error(messageData.error.cart.addItems);
      }
      requestCatchHandler(error);
      return;
    }

    setIsAdding(false);
    message.success(messageData.success.cart.addItems);
  };

  return (
    <div className={clsx("quick-order-actions", className)}>
      <Affix offsetTop={140}>
        <Space direction="vertical">
          {!!items?.length && (
            <div className="quick-order-actions__info">
              Sie haben aktuell {items.length} Artikel in Ihrer
              Schnellbestellung.
            </div>
          )}
          <Button
            className="button buttonPrimary"
            disabled={shouldDisableButtons}
            onClick={updateCart}
            loading={isAdding}
          >
            Alles in den Warenkorb
          </Button>
          <Button
            className="button"
            disabled={shouldDisableButtons}
            onClick={deleteAllItemsFromQuickOrderItems}
          >
            Schnellbestellung löschen
          </Button>
        </Space>
      </Affix>
    </div>
  );
};

export default QuickOrderActions;
