import React from "react";

import clsx from "clsx";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import TrackingHelmet from "../../Matomo/TrackingHelmet";
import PublicPageLayoutWrapper from "../PublicPageLayoutWrapper";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";

interface NotFoundProps {
  className?: string;
}

const NotFound: React.FC<NotFoundProps> = (props: NotFoundProps) => {
  const { className } = props;

  const navigate = useNavigate();

  return (
    <>
      <TrackingHelmet title={pageTitles.notFound} suffix={pageTitleSuffix} />
      <PublicPageLayoutWrapper>
        <div className={clsx("not-found-page", className)}>
          <h1 className="not-found-page__head">404</h1>
          <h5>
            Die Web-Adresse, die Sie eingegeben haben, gibt es auf unserer
            Website nicht.
          </h5>
          <Button
            type="primary"
            className="not-found-page__button"
            size="large"
            onClick={() => navigate("/")}
          >
            Zur Startseite
          </Button>
        </div>
      </PublicPageLayoutWrapper>
    </>
  );
};

export default NotFound;
