import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { Col, Layout, Row } from "antd";

import getImprint from "../../../api/contentful/getImprint";
import handleError from "../../../utils/handleError";
import renderContentfulDocument from "../../../utils/renderContentfulDocument";
import PublicPageLayoutWrapper from "../PublicPageLayoutWrapper";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";

interface ImprintProps {
  className?: string;
}

const Imprint: React.FC<ImprintProps> = (props: ImprintProps) => {
  const { className } = props;

  const [contentfulData, setContentfulData] = useState<any>({});

  useEffect(() => {
    getImprint()
      .then((tacTextContent: any) => {
        if (tacTextContent?.fields) {
          setContentfulData({ ...tacTextContent.fields });
        }

        return tacTextContent;
      })
      .catch(handleError);
  }, []);

  return (
    <>
      <TrackingHelmet title={pageTitles.imprint} suffix={pageTitleSuffix} />

      <PublicPageLayoutWrapper>
        <Layout
          className={clsx("imprint-page", "container-layout--inner", className)}
        >
          <Row>
            <Col xs={12} lg={{ span: 8, offset: 2 }}>
              {contentfulData?.content &&
                renderContentfulDocument(contentfulData.content)}
            </Col>
          </Row>
        </Layout>
      </PublicPageLayoutWrapper>
    </>
  );
};

export default Imprint;
