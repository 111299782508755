import React, { ReactNode } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import moment from "moment";
import HrDivider from "../../divider/Divider";
import { ProductData } from "../../../types/productData";
import getDeliveryDateBasedAttributes from "../getDeliveryDateBasedAttributes";
import isNextMonthCampaign from "../../../utils/isNextMonthCampaign";
import isDeliveryDateNextMonth from "../../../utils/isDeliveryDateNextMonth";
import useStockForAttribute from "../../../hooks/useStockForAttribute";
import getAdvertisementText from "../../../utils/getAdvertisementText";

interface Props {
  children?: ReactNode;
  deliveryDate: ProductData["deliveryDate"];
  productData: ProductData;
}
/**
 * Product Tile wrapper to have consistency over classes etc.
 * @param children {ReactNode}
 * @param deliveryDate {string}
 * @param productData {ProductData}
 * @constructor
 */
const ProductTileWrapper: React.FC<Props> = ({
  children,
  deliveryDate,
  productData,
}: Props) => {
  const { availabilities, attributes } = productData;
  const { isUnavailable, nextAvailability } = getDeliveryDateBasedAttributes({
    deliveryDate,
    availabilities,
  });
  const advertisement = useStockForAttribute(attributes, "advert");

  const { from: campaignStart } = getAdvertisementText(
    advertisement,
    moment(deliveryDate),
    true,
    ""
  );
  const { categoryKey } = useParams<{
    categoryKey?: string;
  }>();

  const isnextMonthlyCampaign = isNextMonthCampaign(categoryKey);
  const deliveryDateIsInNextMonth = isDeliveryDateNextMonth(deliveryDate);

  return (
    <div
      className={clsx("productTile", {
        isUnavailable,
        hasNextAvailability: nextAvailability,
        isNextMonthCampaign:
          !deliveryDateIsInNextMonth &&
          isnextMonthlyCampaign &&
          moment(deliveryDate).isBefore(moment(campaignStart)),
      })}
    >
      {children}

      <HrDivider className="self-end" spacingTop="xs" />
    </div>
  );
};

export default ProductTileWrapper;
