import { Col, Layout, Row } from "antd";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NavNode from "./NavNode";
import { ReactComponent as Arrow2 } from "../../static/svg/arrow.svg";
import useSetCategoriesMenuHovered from "../../hooks/useSetCategoriesMenuHovered";
import useSetCategoryNavigationVisibility from "../../hooks/useSetCategoryNavigationVisibility";
import CategoriesIcon from "../categories/CategoriesIcon";
import SubCategoriesColumn from "./SubCategoriesColumn";
import { CategoryLevel } from "../../types/categoryLevel";
import { Category } from "../../types/category";

interface CategoriesDropdownProps {
  createLinkTo: (pathname: string, categoryKey: string) => string;
}

const initialCategoryLevels: {
  secondLevel: Category[];
  thirdLevel: Category[];
  fourthLevel: Category[];
} = {
  secondLevel: [],
  thirdLevel: [],
  fourthLevel: [],
};

const CategoriesDropdown: React.FC<CategoriesDropdownProps> =
  function CategoriesDropdown({ createLinkTo }: CategoriesDropdownProps) {
    const { categoryRoute, categories, isVisible } = useSelector(
      (state: any) => state.categoryNavigation
    );
    const setCategoriesMenuHovered = useSetCategoriesMenuHovered();
    const setCategoryNavigationVisibility =
      useSetCategoryNavigationVisibility();

    const [activeCategoryKey, setActiveCategoryKey] = useState<string>("");
    const [categoryLevels, setCategoryLevels] = useState(initialCategoryLevels);
    const [eventsBlocked, setEventsBlocked] = useState<boolean>(false);

    /**
     * sets the selected subcategories to the specified level
     * @param {CategoryLevel} level
     * @param {Category[]} data
     */
    const setCategoriesData = (level: CategoryLevel, data: Category[]) => {
      switch (level) {
        case "second":
          setCategoryLevels({
            secondLevel: data || [],
            thirdLevel: [],
            fourthLevel: [],
          });
          break;

        case "third":
          setCategoryLevels({
            ...categoryLevels,
            thirdLevel: data || [],
            fourthLevel: [],
          });
          break;

        case "fourth":
          setCategoryLevels({
            ...categoryLevels,
            fourthLevel: data || [],
          });
          break;

        default:
          break;
      }
    };

    /**
     * handles the hover
     * @param {CategoryLevel | undefined} level
     * @param {Category | undefined} currentCategory
     */
    const handleMouseOnItem = (
      level?: CategoryLevel,
      currentCategory?: Category
    ) => {
      if (!eventsBlocked && currentCategory.categoryKey !== activeCategoryKey) {
        setActiveCategoryKey(currentCategory.categoryKey);
        setCategoriesData(level, currentCategory.children);
      }
    };

    /**
     * determines if a category is active to apply styling to that category
     * @param {Category} currentCategory
     * @param {CategoryLevel} level
     */
    const determineCategoryActiveStatus = useCallback(
      (currentCategory: Category, level: CategoryLevel) => {
        const categoryLevel =
          categoryLevels[`${level}Level` as keyof typeof categoryLevels];
        return (
          activeCategoryKey === currentCategory.categoryKey ||
          (!!currentCategory.children.length &&
            JSON.stringify(categoryLevel) ===
              JSON.stringify(currentCategory.children))
        );
      },
      [activeCategoryKey, categoryLevels]
    );

    useEffect(() => {
      let timestamp: number = null;
      let lastMouseX: number = null;
      let lastMouseY: number = null;

      const mouseHandler = (event: MouseEvent) => {
        if (timestamp === null) {
          timestamp = Date.now();
          lastMouseX = event.screenX;
          lastMouseY = event.screenY;
        }

        // calculate the moving speed/direction of the cursor by comparing previous and current cursor postion within a certain time period
        const now = Date.now();
        const deltaTimestamp = now - timestamp;
        const deltaX = event.screenX - lastMouseX;
        const deltaY = event.screenY - lastMouseY;
        const speedX = Math.round((deltaX / deltaTimestamp) * 100);
        const speedY = Math.round((deltaY / deltaTimestamp) * 100);

        /* determine if the cursor speed/direction results in a potential new category selection
      blocks further hover events if the horizontal cursor speed is more than 5px and the vertical cursor speed is more than +-40px
      By matching all the conditions it can be assumed that the user is moving the cursor to a subcategory tree
      */
        if (speedX > 5 && (speedY < -40 || speedY > 40)) {
          setEventsBlocked(true);
        } else if (speedX === 0) setEventsBlocked(false);

        // set new timestamp and cursor position
        timestamp = now;
        lastMouseX = event.screenX;
        lastMouseY = event.screenY;
      };

      // adding an event listner that receives cursor changes
      document.body.addEventListener("mousemove", mouseHandler);

      return () => {
        // removing the mousemove event listener
        document.body.removeEventListener("mousemove", mouseHandler);
      };
    }, []);

    useEffect(() => {
      if (!isVisible) {
        setCategoryLevels(initialCategoryLevels);
      }
    }, [isVisible]);

    return (
      <div
        className={clsx(
          "categoriesDropdownContainer",
          isVisible && "categoriesDropdownContainer--isVisible"
        )}
      >
        <Layout className="container-layout container-layout--inner">
          <Row
            className="categoriesDropdownInner"
            onMouseLeave={() => {
              setCategoryNavigationVisibility(false);
              setCategoriesMenuHovered(false);
            }}
            onMouseEnter={() => setCategoriesMenuHovered(true)}
          >
            <Col span={3} className="mainCategoriesColumn">
              <ul className="categoriesContent">
                {categories?.children?.length > 0 &&
                  categories.children.map((currentCategory: any) => (
                    <li
                      className="category"
                      key={currentCategory.categoryKey}
                      role="menuitem"
                      onMouseMove={() =>
                        handleMouseOnItem("second", currentCategory)
                      }
                    >
                      <Link
                        to={createLinkTo(
                          categoryRoute,
                          currentCategory.categoryKey
                        )}
                        className="categoryIconContainer"
                      >
                        <CategoriesIcon
                          className="icon categoryIcon"
                          categoryEntry={currentCategory.webshopCategoryEntry}
                        />
                      </Link>
                      <NavNode
                        title={currentCategory.name}
                        route={createLinkTo(
                          categoryRoute,
                          currentCategory.categoryKey
                        )}
                        subTitle={false}
                        className={clsx(
                          "categoryTitle--hasFullHeight",
                          determineCategoryActiveStatus(
                            currentCategory,
                            "second"
                          ) && "categoryTitle--active"
                        )}
                        category={currentCategory}
                      />

                      <div className="categoriesArrowNextContainer">
                        {currentCategory?.children?.length > 0 && (
                          <button
                            className={clsx(
                              "button",
                              "categoriesArrowNext",
                              determineCategoryActiveStatus(
                                currentCategory,
                                "second"
                              ) && "categoriesArrowNext--active"
                            )}
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              setCategoriesData(
                                "second",
                                currentCategory.children
                              );
                            }}
                          >
                            <Arrow2 className="icon arrowNext" />
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
              </ul>
            </Col>
            <SubCategoriesColumn
              categoryRoute={categoryRoute}
              createLinkTo={createLinkTo}
              currentCategories={categoryLevels.secondLevel}
              determineCategoryActiveStatus={determineCategoryActiveStatus}
              handleMouseOnItem={handleMouseOnItem}
              level="third"
            />
            <SubCategoriesColumn
              categoryRoute={categoryRoute}
              createLinkTo={createLinkTo}
              currentCategories={categoryLevels.thirdLevel}
              determineCategoryActiveStatus={determineCategoryActiveStatus}
              handleMouseOnItem={handleMouseOnItem}
              level="fourth"
            />
            <SubCategoriesColumn
              categoryRoute={categoryRoute}
              createLinkTo={createLinkTo}
              currentCategories={categoryLevels.fourthLevel}
              determineCategoryActiveStatus={determineCategoryActiveStatus}
              handleMouseOnItem={handleMouseOnItem}
            />
          </Row>
        </Layout>
      </div>
    );
  };

export default CategoriesDropdown;
