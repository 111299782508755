const initialState: any = {
  activeIndex: <number>0,
};

const impulseReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "impulse/set-active-index": {
      const { activeIndex } = payload;

      if (!Number.isInteger(activeIndex)) {
        return state;
      }

      return {
        ...state,
        activeIndex,
      };
    }

    default:
      return state;
  }
};

export default impulseReducer;
