import React, { useState } from "react";

import clsx from "clsx";
import { Button, Col, Divider, message, Row, Space } from "antd";
import axios from "axios";

import getCancelTokenSource from "../../../api/getCancelTokenSource";
import getBnnDocument, { BnnExportType } from "../../../api/bnn/getBnnDocument";
import requestCatchHandler from "../../../api/requestCatchHandler";
import BackButton from "../../backButton/BackButton";
import HrDivider from "../../divider/Divider";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { ContentBlock } from "../../atoms";
import { messageData, pageTitles, pageTitleSuffix } from "../../../appConfig";
import { ReactComponent as DownloadIcon } from "../../../static/svg/download.svg";

type LoadingButtonType = "bnn0" | "bnn1";

interface DownloadsProps {
  className?: string;
}

const Downloads: React.FC<DownloadsProps> = (props: DownloadsProps) => {
  const { className } = props;

  const [buttonLoadingStates, setButtonLoadingStates] = useState<
    Record<LoadingButtonType, boolean>
  >({
    bnn0: false,
    bnn1: false,
  });

  const handleBnnDownload = (exportType: BnnExportType) => {
    const cancelTokenSource = getCancelTokenSource();
    setButtonLoadingStates((prevState) => {
      return { ...prevState, [`bnn${exportType}`]: true };
    });

    getBnnDocument({ exportType, cancelTokenSource })
      .then(() => {
        message.success(messageData.success.bnn.success);
        setButtonLoadingStates((prevState) => {
          return { ...prevState, [`bnn${exportType}`]: false };
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error(messageData.error.bnn.notFound);
          setButtonLoadingStates((prevState) => {
            return { ...prevState, [`bnn${exportType}`]: false };
          });
        }
        requestCatchHandler(error);
      });
  };

  return (
    <>
      <TrackingHelmet title={pageTitles.downloads} suffix={pageTitleSuffix} />

      <ShopCategoryNavigation />

      <ContentBlock
        showHeader={false}
        className={clsx("downloads-page", className)}
      >
        <BackButton />

        <Row className="flex items-center mb-m" justify="space-between">
          <Col xs={12} md={8}>
            <h1 className="text-truncate">Downloads</h1>
          </Col>
        </Row>

        <HrDivider spacingTop="xs" size={2} spacingBottom="l" />

        <Divider orientation="left">BNN Preislisten</Divider>

        <Space direction="vertical" className="downloads-page__bnn">
          <Button
            className="button buttonPrimary buttonWithIcon"
            icon={<DownloadIcon />}
            size="large"
            onClick={() => handleBnnDownload(0)}
            loading={buttonLoadingStates?.bnn0 || false}
            disabled={buttonLoadingStates?.bnn0 || false}
          >
            Gesamt-Preislisten
          </Button>
          <Button
            className="button buttonPrimary buttonWithIcon"
            icon={<DownloadIcon />}
            size="large"
            onClick={() => handleBnnDownload(1)}
            loading={buttonLoadingStates?.bnn1 || false}
            disabled={buttonLoadingStates?.bnn1 || false}
          >
            Obst & Gemüse-Preislisten
          </Button>
        </Space>
      </ContentBlock>
    </>
  );
};

export default Downloads;
