import moment from "moment";
import { Asset, Entry } from "contentful";

import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import { contentfulDateTimeFormat } from "../../utils/dateFormats";
import stockNameMapping from "../../utils/stockNameMapping";
import { ContentfulWebshopProductInformationEntry } from "../../types/contentfulWebshopProductInformationEntry";
import { StockType } from "../../types/stock";

interface WebshopProductInformationParams {
  limit: number;
  skip: number;
  withTotal?: boolean;
  stockName?: StockType;
}

interface WebshopProductInformationResponse {
  items: ContentfulWebshopProductInformationEntry[];
  total: number;
}

/**
 * @param limit
 * @param skip
 * @param withTotal
 * @param stockName
 *
 * @return Promise<any>
 */
const getWebshopProductInformation = async ({
  limit,
  skip,
  withTotal = true,
  stockName = "HL",
}: WebshopProductInformationParams): Promise<WebshopProductInformationResponse> =>
  getContentfulContentType({
    ...contentfulContentTypes.webshopProductInformation,
    limit,
    skip,
    order: "-fields.publish_date,-sys.createdAt",
    inclusion: {
      "fields.publish_date[lte]": moment().format(contentfulDateTimeFormat),
      "fields.unpublish_date[gte]": moment().format(contentfulDateTimeFormat),
      "fields.stock_name[in]": `Alle,${stockNameMapping?.[stockName] || ""}`,
    },
    withTotal,
  }).then((response: any) => {
    if (!response) {
      return Promise.reject(response);
    }
    return {
      items:
        response.items?.map((webshopProductInformationEntry: Entry<any>) => {
          const {
            content,
            content_preview: contentPreview,
            headline,
            image_desktop: imageDesktop,
            pdf_attachments: pdfAttachments,
            product_information_type: productInformationType,
            product_skus: productSkus,
            publish_date: publishDate,
          } = webshopProductInformationEntry.fields;

          return {
            id: webshopProductInformationEntry.sys.id,
            content,
            contentPreview,
            headline,
            imageDesktopUrl: imageDesktop?.fields?.file?.url || "",
            pdfFiles: pdfAttachments?.map((attachment: Asset) => {
              return {
                downloadUrl: attachment?.fields?.file?.url || "",
                fileName:
                  attachment?.fields?.title ||
                  attachment?.fields?.file?.fileName ||
                  "",
              };
            }),
            productInformationType,
            productSkus,
            publishDate,
          } as ContentfulWebshopProductInformationEntry;
        }) || [],
      total: response.total,
    } as WebshopProductInformationResponse;
  });

export default getWebshopProductInformation;
