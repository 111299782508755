import { useMemo } from "react";

import { CheapCartMetadata } from "../types/cheapCartMetadata";
import { SkuOrderData } from "../types/productPreorderModal";

interface UseGetSkuOrderDataProps {
  openCarts: CheapCartMetadata[];
  sku: string;
}

const useGetSkuOrderData = (props: UseGetSkuOrderDataProps) => {
  const { openCarts = [], sku } = props;

  const skuOrderData: SkuOrderData[] = useMemo(
    () =>
      openCarts
        .filter(
          (cartMetadata: CheapCartMetadata) =>
            !!cartMetadata?.items?.find((item) => item?.sku === sku) || false
        )
        .map((cartMetadata: CheapCartMetadata) => {
          const cartItem = cartMetadata?.items?.find(
            (item) => item?.sku === sku
          );
          return {
            quantity: String(cartItem?.quantity || ""),
            deliveryDate: String(cartMetadata?.deliveryDate || ""),
          };
        }) || [],
    [openCarts, sku]
  );

  return { skuOrderData };
};

export default useGetSkuOrderData;
