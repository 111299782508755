import React from "react";

import clsx from "clsx";
import { Spin } from "antd";

interface LoaderComponentProps {
  spinning?: boolean;
  className?: string;
}

const LoaderComponent: React.FC<LoaderComponentProps> = (
  props: LoaderComponentProps
) => {
  const { spinning = true, className } = props;

  return (
    <div className={clsx("loader-component", className)}>
      <Spin
        spinning={spinning}
        className="loader-component__spin"
        size="large"
        color="white"
      />
    </div>
  );
};

export default LoaderComponent;
