const getRelativeFromAbsoluteUrl = (url: string): string => {
  try {
    const urlObj = new URL(url);

    return urlObj?.href?.replace(urlObj?.origin || "", "");
  } catch (error) {
    return url;
  }
};

export default getRelativeFromAbsoluteUrl;
