import React, { useCallback, useEffect, useState } from "react";

import clsx from "clsx";
import { debounce } from "debounce";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "antd";
import queryString, { ParsedQuery } from "query-string";

import { updateFilterStates } from "../../products/ProductsFilter/filterFunctions";
import { delays, locationSearchQueryParameter } from "../../../appConfig";

interface SearchFilterProps {
  maxLength?: number;
  searchDelay?: number;
  className?: string;
  placeholder?: string;
  searchName?: string;
  searchFilterName?: string;
  resetPagination?: boolean;
  onSearchBeforeNavigate?: () => void;
  addonAfter?: JSX.Element;
}

const SearchFilter: React.FC<SearchFilterProps> = (props) => {
  const {
    maxLength = 30,
    searchDelay = delays.searchFilter,
    className,
    placeholder = "Suche in diesem Bereich",
    searchName = locationSearchQueryParameter.searchTerm,
    searchFilterName = locationSearchQueryParameter.searchFilter,
    resetPagination = false,
    onSearchBeforeNavigate,
    addonAfter,
  } = props;

  const { search } = useLocation();
  const navigate = useNavigate();

  const [parsedSearch, setParsedSearch] = useState<ParsedQuery>(
    queryString.parse(search)
  );
  const [inputValue, setInputValue] = useState<string>(
    (parsedSearch?.[searchFilterName] || "") as string
  );
  const [allowClear, setAllowClear] = useState<boolean>(!!inputValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doSearch = useCallback(
    debounce((value: string) => {
      if (!value && !allowClear) {
        return;
      }

      if (value && value.length > maxLength) {
        return;
      }

      if (!value && allowClear) {
        setAllowClear(false);
      }

      const shouldReset = parsedSearch[searchFilterName] !== value;

      if (resetPagination && shouldReset) {
        parsedSearch[locationSearchQueryParameter.page] = "";
      }

      parsedSearch[searchFilterName] = value;

      if (onSearchBeforeNavigate) {
        onSearchBeforeNavigate();
      }
      updateFilterStates(navigate, searchFilterName, [value], search);
    }, searchDelay),
    [parsedSearch, navigate]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    if (value === "") {
      updateFilterStates(navigate, searchFilterName, [value], search);
    } else {
      doSearch(value);
    }
  };

  useEffect(() => {
    setParsedSearch((prevState: ParsedQuery) => {
      const newState = queryString.parse(search);
      if (
        prevState?.[searchName] &&
        prevState?.[searchName] !== newState?.[searchName]
      ) {
        setInputValue("");
      }
      return newState;
    });
  }, [search, searchName]);

  return (
    <div className={clsx("search-filter", className)}>
      <Input
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={handleChange}
        value={inputValue}
        allowClear
        addonAfter={addonAfter}
      />
    </div>
  );
};

export default SearchFilter;
