import React, { useCallback } from "react";

import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "antd";
import moment from "moment/moment";
import queryString from "query-string";

import useGetWeekplannerWeekDaysData from "../../../../../hooks/useGetWeekplannerWeekDaysData";
import useUpdateUrlFragments from "../../../../../hooks/useUpdateUrlFragments";
import updateWeekplannerDates from "../../../../../state/actions/updateWeekplannerDates";
import { apiDateFormat } from "../../../../../utils/dateFormats";
import { ReactComponent as Arrow } from "../../../../../static/svg/arrow.svg";
import { RootState } from "../../../../../types/rootState";

type DirectionType = "back" | "next";

interface WeekplannerItemsHeaderPaginationProps {
  className?: string;
  onPaginate: () => void;
  isLoading?: boolean;
}

const WeekplannerItemsHeaderPagination: React.FC<
  WeekplannerItemsHeaderPaginationProps
> = (props: WeekplannerItemsHeaderPaginationProps) => {
  const { className, onPaginate = () => {}, isLoading } = props;

  const { search } = useLocation();

  const { weekDaysData } = useGetWeekplannerWeekDaysData();

  const setUpdateUrlFragments = useUpdateUrlFragments();

  const { calenderWeek, apiDate: currentDate } = weekDaysData?.[0] || {};

  const { minWeek, maxWeek } = useSelector(
    (state: RootState) => state.weekplannerData
  );

  const setWeek = useCallback(
    (direction: DirectionType) => {
      const { apiDate: currentDay, apiDate: currentCalenderWeek } =
        weekDaysData?.[0] || {};

      if (typeof onPaginate === "function") {
        onPaginate();
      }

      let nextWeekStartDate;

      // create new data only if the step seems legit, otherwise opt out
      if (
        direction === "next" &&
        moment(currentCalenderWeek).add(1, "week").format(apiDateFormat) <=
          maxWeek
      ) {
        nextWeekStartDate = moment(currentDay).add(7, "days");
      } else if (
        direction === "back" &&
        moment(currentCalenderWeek).subtract(1, "week").format(apiDateFormat) >=
          minWeek
      ) {
        nextWeekStartDate = moment(currentDay).subtract(7, "days");
      } else {
        return null;
      }

      // update redux weekdays
      const newWeekData = updateWeekplannerDates(nextWeekStartDate);

      const { search: paramSearch } = queryString.parse(search);
      const searchTerm = paramSearch?.length ? paramSearch.toString() : null;

      if (newWeekData) {
        setUpdateUrlFragments({
          context: "weekPlanner",
          parameters: {
            searchTerm,
            from: newWeekData[0]?.apiDate,
            to: newWeekData[newWeekData.length - 1]?.apiDate,
            page: undefined,
          },
        });
      }

      return true;
    },
    [maxWeek, minWeek, setUpdateUrlFragments, weekDaysData, search, onPaginate]
  );

  return (
    <div
      className={clsx(
        "weekplanner-items-header-pagination",
        "weekplanner-items-header-cell",
        className
      )}
    >
      <Button
        disabled={currentDate === minWeek || isLoading}
        onClick={() => setWeek("back")}
        type="text"
        title="Zurück"
      >
        <Arrow />
      </Button>

      <div>
        <span className="pagination-week">KW {calenderWeek}</span>
        {weekDaysData[0].dateString.split(", ")[1]} -{" "}
        {weekDaysData[5].dateString.split(", ")[1]}
      </div>

      <Button
        disabled={currentDate === maxWeek || isLoading}
        onClick={() => setWeek("next")}
        type="text"
        title="Weiter"
      >
        <Arrow />
      </Button>
    </div>
  );
};

export default WeekplannerItemsHeaderPagination;
