import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Col, RadioChangeEvent, Row } from "antd";
import { CancelTokenSource } from "axios";

import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import requestCatchHandler from "../../../api/requestCatchHandler";
import setAlreadyOrdered from "../../../state/actions/setAlreadyOrdered";
import ButtonBackToTop from "../../buttons/ButtonBackToTop";
import ShopCategoryNavigation from "../../navigation/ShopCategoryNavigation";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import QuickOrderForm from "./QuickOrderForm";
import QuickOrderUpload from "./QuickOrderUpload";
import QuickOrderItems from "./QuickOrderItems";
import QuickOrderActions from "./QuickOrderActions";
import { ContentBlock, QuickOrderHeader } from "../../atoms";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import {
  pageTitles,
  pageTitleSuffix,
  routePathNames,
} from "../../../appConfig";
import { ProductData } from "../../../types/productData";
import { RootState } from "../../../types/rootState";

interface QuickOrderProps {
  className?: string;
}

const QuickOrder: React.FC<QuickOrderProps> = (props: QuickOrderProps) => {
  const { className } = props;

  const { type = "" } = useParams<{
    type: "upload" | "";
  }>();

  const navigate = useNavigate();
  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-xl")})`
  );

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());

  const { deliveryDate } = useSelector(
    (state: RootState) => state.currentCartMetaData
  );

  const [product, setProduct] = useState<ProductData>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  useEffect(() => {
    if (type !== "upload") {
      cancelTokenSource.current = cancelAndRenewCancelToken(
        cancelTokenSource.current
      );

      setAlreadyOrdered({
        cancelTokenSource: cancelTokenSource.current,
      }).catch((error) => {
        requestCatchHandler(error);
      });
    }

    return () => {
      cancelTokenSource?.current?.cancel();
    };
  }, [type]);

  return (
    <>
      <TrackingHelmet title={pageTitles.quickOrder} suffix={pageTitleSuffix} />

      <ShopCategoryNavigation />

      <ContentBlock
        showHeader={false}
        className={clsx("quick-order-page", className)}
      >
        <QuickOrderHeader
          type={type}
          onChange={(event: RadioChangeEvent) => {
            setProduct(null);
            navigate(`${routePathNames.quickOrder}/${event.target.value}`);
          }}
        />

        <Row gutter={[10, 10]} className="mt-m quick-order-page__body">
          {type === "upload" ? (
            <>
              <Col span={12} className="mb-s">
                <QuickOrderUpload deliveryDate={deliveryDate} />
              </Col>
            </>
          ) : (
            <Row gutter={40}>
              <Col span={12} className="mb-s">
                <QuickOrderForm
                  deliveryDate={deliveryDate}
                  product={product}
                  setProduct={setProduct}
                  isSearching={isSearching}
                  setIsSearching={setIsSearching}
                />
              </Col>
              <Col span={browserIsDesktop ? 9 : 12} className="mt-sm">
                <QuickOrderItems />
              </Col>
              <Col span={browserIsDesktop ? 3 : 12} className="mt-sm">
                <QuickOrderActions isDisabled={isSearching} />
              </Col>
            </Row>
          )}
        </Row>
      </ContentBlock>
      <ButtonBackToTop />
    </>
  );
};

export default QuickOrder;
