import { Sys } from "contentful";
import storeDispatch from "../../../state/actions/storeDispatch";

/**
 * test if the given contentful timestamps on a the terms and conditions is newer
 * than the accepted timestamp saved on the user object
 * it is possible, that there is no 'updatedAt' value present,
 * then it must check the createdAt timestamp
 * @param termsTimeStamps {Sys}
 * @param acceptedTimestamp {number}
 */
const isTermsAndConditionsVersionNew = (
  termsTimeStamps: Sys,
  acceptedTimestamp: number
) => {
  const { createdAt, updatedAt } = termsTimeStamps;

  /*
   * Create unix timestamp from date string,
   * use always updatedAt, only if not available use createdAt as fallback
   */
  const termsTimestamp = Math.floor(
    new Date(updatedAt || createdAt).getTime() / 1000
  );

  /*
   * Put terms and condition (change) timestamp to store
   */
  storeDispatch("terms-and-conditions/set-timestamp", termsTimestamp);

  /*
   * If acceptedTimestamp is smaller than the terms timestamp,
   * a new version is likely be available and must be accepted again
   */
  return acceptedTimestamp < termsTimestamp;
};

export default isTermsAndConditionsVersionNew;
