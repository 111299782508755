import { AxiosResponse } from "axios";

import clearStore from "../../state/actions/clearStore";
import handleError from "../../utils/handleError";

interface MinimalResponseSet {
  status: number;
  statusText: string;
}
const redirectIfUnauthorized = (
  response: AxiosResponse | MinimalResponseSet
) => {
  const { status, statusText } = response;

  if (status === 401 || statusText.toLowerCase() === "unauthorized") {
    clearStore();
  } else {
    handleError(statusText);
  }
};

export default redirectIfUnauthorized;
