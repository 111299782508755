import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import { bnnDocumentEndpoint } from "../endpoints";

export type BnnExportType = 0 | 1;

interface GetBnnDocumentProps {
  exportType: BnnExportType;
  cancelTokenSource: CancelTokenSource;
}

const getBnnDocument = async (props: GetBnnDocumentProps) => {
  const { exportType = 0, cancelTokenSource } = props;

  return axiosWithAuth()
    .get(`${bnnDocumentEndpoint}?type=${exportType}`, {
      responseType: "blob",
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (response?.status !== 200) {
        Promise.reject(response);
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `PL${exportType === 1 ? "_OG" : ""}.bnn`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export default getBnnDocument;
