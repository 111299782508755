import moment from "moment/moment";

import { QuickOrderStateItem, QuickOrderState } from "../../types/quickOrder";
import { ProductData } from "../../types/productData";

const initialState: QuickOrderState = {
  items: <QuickOrderStateItem[]>[],
};

const quickOrderReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  const sortByAddedAtDesc = (
    a: QuickOrderStateItem,
    b: QuickOrderStateItem
  ) => {
    if (!a?.addedAt || !b?.addedAt || a.addedAt === b.addedAt) {
      return 0;
    }

    return moment(a.addedAt).isSameOrAfter(moment(b.addedAt), "second")
      ? -1
      : 1;
  };

  switch (type) {
    case "quick-order/add-item": {
      const { item } = payload;

      const itemIndex = state?.items?.findIndex(
        (stateItem: QuickOrderStateItem) =>
          stateItem.product.sku === item.product.sku
      );

      const items =
        itemIndex !== -1
          ? state?.items?.map((stateItem: QuickOrderStateItem, index: number) =>
              index !== itemIndex
                ? stateItem
                : {
                    ...stateItem,
                    quantity: stateItem.quantity + item.quantity,
                    addedAt: moment(),
                  }
            )
          : [...(state?.items || []), item];

      return {
        ...state,
        items: items.slice().sort(sortByAddedAtDesc),
      };
    }

    case "quick-order/delete-item": {
      const { item } = payload;

      return {
        ...state,
        items: state?.items?.filter(
          (stateItem: QuickOrderStateItem) =>
            stateItem?.product?.sku !== item?.product?.sku
        ),
      };
    }

    case "quick-order/delete-all-items": {
      return {
        ...state,
        items: [],
      };
    }

    case "quick-order/update-all-items": {
      const { products } = payload;

      return {
        ...state,
        items:
          products
            ?.map((productData: ProductData) => {
              const stateItem: QuickOrderStateItem = state?.items?.find(
                (item: QuickOrderStateItem) =>
                  item?.product?.sku === productData?.sku
              );

              if (!stateItem) {
                return null;
              }

              return { ...stateItem, product: productData };
            })
            ?.filter(Boolean)
            .slice()
            ?.sort(sortByAddedAtDesc) || [],
      };
    }

    default:
      return state;
  }
};

export default quickOrderReducer;
