import React from "react";

import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";

import { ReactComponent as BackIcon } from "../../static/svg/back.svg";

interface BackButtonProps {
  title?: string;
  to?: string;
  withBreadcrumbs?: boolean;
}

/**
 * @param {string} title
 * @param {string} to
 * @param {boolean} withBreadcrumbs
 * @constructor
 */
const BackButton: React.FC<BackButtonProps> = function BackButton({
  title,
  to,
  withBreadcrumbs,
}: BackButtonProps) {
  const navigate = useNavigate();
  const isLargeScreen = useMedia(`(min-width: ${getCssVariable("screen-md")})`);

  const withSeparator = withBreadcrumbs && isLargeScreen;

  /**
   * function to determine where to go back
   */
  const goBack = () => {
    if (to) {
      navigate(to);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex flex-row items-center mr-s hidden-print">
      <button
        type="button"
        className={clsx(
          "button buttonText buttonTextDecoration--inverted buttonWithIcon",
          withSeparator && "mr-s"
        )}
        onClick={goBack}
      >
        <BackIcon width="22" height="22" className="icon" />
        {title || "Zurück"}
      </button>
      {withSeparator && "|"}
    </div>
  );
};

export default BackButton;
