import { persistor, store } from "../../store";

const clearStore = () => {
  store.dispatch({
    type: "store/clear",
  });
  persistor.pause();
  persistor.flush().then(() => {
    persistor.purge();
    window.setTimeout(() => {
      persistor.persist();
    }, 250);
  });
};

export default clearStore;
