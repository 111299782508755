import React from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";

import WeekplannerItemsHeaderCarts from "./WeekplannerItemsHeaderCarts";
import WeekplannerItemsHeaderDefault from "./WeekplannerItemsHeaderDefault";
import WeekplannerItemsHeaderPagination from "./WeekplannerItemsHeaderPagination";
import { RootState } from "../../../../types/rootState";

interface WeekplannerItemsHeaderProps {
  className?: string;
  onPaginate?: () => void;
  isLoading?: boolean;
}

const WeekplannerItemsHeader: React.FC<WeekplannerItemsHeaderProps> = (
  props: WeekplannerItemsHeaderProps
) => {
  const { className, onPaginate = () => {}, isLoading } = props;

  const { weekplannerUseSimpleMode: useSimpleMode = false } = useSelector(
    (state: RootState) => state?.userData || {}
  );

  return (
    <div
      className={clsx(
        "weekplanner-items-header",
        useSimpleMode && "weekplanner-items-header--simple",
        className
      )}
    >
      {useSimpleMode ? (
        <>
          <WeekplannerItemsHeaderDefault
            title=""
            className="weekplanner-items-header-default__sku"
          />
          <WeekplannerItemsHeaderPagination
            isLoading={isLoading}
            onPaginate={onPaginate}
            className="weekplanner-items-header-default__main"
          />
          <WeekplannerItemsHeaderCarts />
          <WeekplannerItemsHeaderDefault
            title="Ursprung"
            className="weekplanner-items-header-default__attribute"
          />
          <WeekplannerItemsHeaderDefault
            title="Qualität"
            className="weekplanner-items-header-default__attribute"
          />
          <WeekplannerItemsHeaderDefault
            title="VPE"
            className="weekplanner-items-header-default__attribute"
          />
          <WeekplannerItemsHeaderDefault
            title="1 Staffel"
            className="weekplanner-items-header-default__volume-price"
          />
          <WeekplannerItemsHeaderDefault
            title="2 Staffel"
            className="weekplanner-items-header-default__volume-price"
          />{" "}
          <WeekplannerItemsHeaderDefault
            title="5 Staffel"
            className="weekplanner-items-header-default__volume-price"
          />
          <WeekplannerItemsHeaderDefault
            title="10 Staffel"
            className="weekplanner-items-header-default__volume-price"
          />
          <WeekplannerItemsHeaderDefault
            title="Rollwagen"
            className="weekplanner-items-header-default__volume-price"
          />
          <WeekplannerItemsHeaderDefault
            title="Palette"
            className="weekplanner-items-header-default__volume-price"
          />
          <WeekplannerItemsHeaderDefault
            title=""
            className="weekplanner-items-header-default__actions"
          />
          <div style={{ scrollbarGutter: "stable", overflow: "hidden" }} />
        </>
      ) : (
        <>
          <WeekplannerItemsHeaderPagination
            isLoading={isLoading}
            onPaginate={onPaginate}
          />
          <WeekplannerItemsHeaderDefault
            title="Preis"
            className="weekplanner-items-header-default__price"
          />
          <WeekplannerItemsHeaderCarts />
          <div style={{ scrollbarGutter: "stable", overflow: "hidden" }} />
        </>
      )}
    </div>
  );
};

export default WeekplannerItemsHeader;
