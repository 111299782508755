import React, { BaseSyntheticEvent } from "react";
import clsx from "clsx";

import { Link } from "react-router-dom";
import isExternalUrl from "../../../utils/isExternalUrl";
import getRelativeFromAbsoluteUrl from "../../../utils/getRelativeFromAbsoluteUrl";

interface QuickLinkProps {
  label: string;
  href: string;
  icon?: React.ReactNode;
  linkType?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "default"
    | "primary--inverted"
    | "primary--outlined";
  className?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  onClick?: (e: BaseSyntheticEvent) => void;
}

const QuickLink: React.FC<QuickLinkProps> = (props) => {
  const {
    label,
    href,
    icon,
    linkType = "default",
    className,
    target = "_self",
    onClick = null,
  } = props;

  if (!label || !label?.trim()) {
    return null;
  }

  return (
    <>
      {isExternalUrl(href) ? (
        <a
          href={href}
          className={clsx("quick-link", `quick-link--${linkType}`, className)}
          target="_blank"
          title={label}
          rel="noopener noreferrer"
        >
          {!!icon && <span className="quick-link__icon">{icon}</span>}
          <span className="quick-link__label">{label}</span>
        </a>
      ) : (
        <Link
          to={getRelativeFromAbsoluteUrl(href)}
          className={clsx("quick-link", `quick-link--${linkType}`, className)}
          target={target}
          title={label}
          onClick={typeof onClick === "function" ? onClick : null}
        >
          {!!icon && <span className="quick-link__icon">{icon}</span>}
          <span className="quick-link__label">{label}</span>
        </Link>
      )}
    </>
  );
};

export default QuickLink;
