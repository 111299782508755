import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { Col, Layout, Row } from "antd";

import getOrderingInformation from "../../../api/contentful/getOrderingInformation";
import handleError from "../../../utils/handleError";
import renderContentfulDocument from "../../../utils/renderContentfulDocument";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";

interface OrderingInformationProps {
  className?: string;
}

const OrderingInformation: React.FC<OrderingInformationProps> = (
  props: OrderingInformationProps
) => {
  const { className } = props;

  const [contentfulData, setContentfulData] = useState<any>({});

  useEffect(() => {
    getOrderingInformation()
      .then((orderingInformationContent: any) => {
        if (orderingInformationContent?.fields) {
          setContentfulData({ ...orderingInformationContent.fields });
        }

        return orderingInformationContent;
      })
      .catch(handleError);
  }, []);

  return (
    <>
      <TrackingHelmet
        title={pageTitles.orderingInformation}
        suffix={pageTitleSuffix}
      />

      <Layout
        className={clsx(
          "ordering-information-page",
          "container-layout--inner",
          className
        )}
      >
        <Row>
          <Col xs={12} lg={{ span: 8, offset: 2 }}>
            {contentfulData?.content &&
              renderContentfulDocument(contentfulData.content)}
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default OrderingInformation;
