import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import InfoCenterAssets from "./InfoCenterAssets";
import InfoCenterFolders from "./InfoCenterFolders";

interface Props {
  onClick: (node: Record<string, any>) => void;
  isWelcomeScreen?: boolean;
}

const InfoCenterContent: React.FC<Props> = ({
  onClick,
  isWelcomeScreen = false,
}: Props) => {
  const { activeFolder } = useSelector((state: any) => state.infoCenter);

  if (!activeFolder) {
    return null;
  }

  const { assets, children } = activeFolder;
  const hasAssets = assets?.length > 0;
  const hasChildren = children?.length > 0;
  const isEmptyFolder = !hasAssets && !hasChildren;

  return (
    /* current folder is empty */
    isEmptyFolder ? (
      <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
        <Col span={12}>
          {isWelcomeScreen ? (
            <div>
              Liebe Kund*innen, <br />
              <br /> in der Infothek finden Sie alles Wissenswerte rund um
              Weiling wie aktuelle Informationen, Kataloge, Neuprodukte,
              Lieferanteninfos und vieles mehr!
            </div>
          ) : (
            <i>Dieser Ordner ist leer.</i>
          )}
        </Col>
      </Row>
    ) : (
      <>
        {/* folders */}
        <InfoCenterFolders folders={children} onClick={onClick} />

        {/* assets e.g. PDFs */}
        <InfoCenterAssets assets={assets} />
      </>
    )
  );
};

export default InfoCenterContent;
