import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Col, Layout, message, Result, Row } from "antd";
import axios, { CancelTokenSource } from "axios";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import ShopCategoryNavigation from "../navigation/ShopCategoryNavigation";
import ProductDetailView from "./ProductDetailView";
import ProductDetailInfo from "./ProductDetailInfo";
import requestCatchHandler from "../../api/requestCatchHandler";
import ProductSlider from "../productSlider/ProductSlider";
import HrDivider from "../divider/Divider";
import ProductVideo from "./ProductVideo";
import { ProductData } from "../../types/productData";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import getContentfulContentType from "../../api/contentful/getContentfulContentType";
import {
  contentfulContentTypes,
  locationSearchQueryParameter,
  messageData,
  pageTitles,
  pageTitleSuffix,
  sprykerGlossaryKeys,
} from "../../appConfig";
import NewsBanner from "../NewsBanner/NewsBanner";
import LoaderComponent from "../atoms/LoaderComponent";
import useGetDeliveryDate from "../../hooks/useGetDeliveryDate";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import ButtonBackToTop from "../buttons/ButtonBackToTop";
import ProductAddToPriceTagListButton from "../product/ProductAddToPriceTagListButton";
import setPriceTagList from "../../state/actions/setPriceTagList";
import ProductAddToFavouritesListButton from "../product/ProductAddToFavouritesListButton";
import setFavouriteLists from "../../state/actions/setFavouriteLists";
import { BreadcrumbEntry } from "../../types/breadcrumb";
import getProductDetail from "../../api/products/getProductDetail";
import BackButton from "../backButton/BackButton";
import { GlossaryEntry } from "../../types/glossary";
import { RootState } from "../../types/rootState";
import setAlreadyOrdered from "../../state/actions/setAlreadyOrdered";
import ProductDetailDownloads from "./ProductDetailDownloads";
import { productAttributes } from "../../api/productAttributes";
import ProductDetailModalContext from "../../contexts/ProductDetailModalContext";
import TrackingHelmet from "../Matomo/TrackingHelmet";
import WeekplannerProductDetailModalContext from "../../contexts/WeekplannerProductDetailModalContext";

interface ProductDetailProps {
  sku?: string;
}

const ProductDetail: React.FC<ProductDetailProps> = (props) => {
  const isInProductDetailModal = useContext(ProductDetailModalContext);
  const isInWeekplannerProductDetailModal = useContext(
    WeekplannerProductDetailModalContext
  );
  const dispatch = useDispatch();
  const { sku: skuProp } = props;
  const { sku: skuQueryParam } = useParams<{ sku: string }>();

  const sku = skuProp ?? skuQueryParam;

  // get parameter for breadcrumb path
  const { search } = useLocation();
  const {
    [locationSearchQueryParameter.breadcrumbReference]: breadcrumbReference,
  } = queryString.parse(search);

  const [deliveryDate] = useGetDeliveryDate();
  const [isLoading, setIsLoading] = useState(false);
  const [breadcrumbData, setBreadcrumbData] = useState<BreadcrumbEntry[]>([]);
  const [productData, setProductData] = useState<ProductData | null>();
  const [ingredientsLegend, setIngredientsLegend] = useState<string>();
  const [herbalNotice, setHerbalNotice] = useState<string>("");
  const [contentfulBanner, setContentfulBanner] = useState(null);
  const [hasDownloads, setHasDownloads] = useState<boolean>(false);
  const navigate = useNavigate();

  const orderItemIndexVersion = useSelector(
    (state: RootState) => state.alreadyOrdered.version
  );
  /**
   * get product
   * @param cancelTokenSource {CancelTokenSource}
   */
  const getProduct = useCallback(
    async (cancelTokenSource: CancelTokenSource) => {
      setIsLoading(true);

      getProductDetail({
        cancelTokenSource,
        deliveryDate,
        productSku: sku,
        skipAvailabilityCheck: true,
        breadcrumbReference: breadcrumbReference
          ? String(breadcrumbReference)
          : "",
      })
        .then(
          ({
            attributeNames,
            breadcrumb,
            concreteProducts,
            glossary,
            orderItemIndexVersion: version,
          }) => {
            // store attribute names to redux
            dispatch({
              type: "products/set-attribute-names",
              payload: attributeNames,
            });

            if (version !== null && version !== orderItemIndexVersion) {
              if (version === "0")
                dispatch({
                  type: "alreadyOrdered/empty-order-item-index",
                });
              else {
                setAlreadyOrdered({
                  cancelTokenSource,
                });
              }
            }
            // breadcrumbs are in reverse order (first is deepest, so we need to reverse them)
            setBreadcrumbData(breadcrumb?.reverse() || []);

            // set the results first product or none if not available for the date
            setProductData(concreteProducts?.[0] || null);

            setIngredientsLegend(
              glossary?.find(
                (glossaryEntry: GlossaryEntry) =>
                  glossaryEntry.glossaryKey ===
                  sprykerGlossaryKeys.ingredientsLegend
              )?.value || ""
            );

            setHerbalNotice(
              glossary?.find(
                (glossaryEntry: GlossaryEntry) =>
                  glossaryEntry.glossaryKey === sprykerGlossaryKeys.herbalNotice
              )?.value || ""
            );

            const { [productAttributes.bioId]: bioId } =
              concreteProducts?.[0]?.attributes || {};
            setHasDownloads(!!bioId || false);

            setIsLoading(false);
          }
        )
        .catch((err) => {
          setIsLoading(false);

          if (!axios.isCancel(err)) {
            message.error(messageData.error.unexpected);
          }

          requestCatchHandler(err);
        });
    },
    [breadcrumbReference, deliveryDate, dispatch, orderItemIndexVersion, sku]
  );

  /**
   * get a possible banner
   * API gets a filter parameter based on the sku which has to be
   * included in the contentful sku list entry
   * otherwise the response is empty
   */
  const getContentfulBanner = useCallback(async () => {
    getContentfulContentType({
      ...contentfulContentTypes.webshopDetailPageBanner,
      inclusion: {
        "fields.sku_list[in]": sku,
      },
    }).then((response: any) => {
      if (response && Array.isArray(response)) {
        const bannerContent = response.map(
          (bannerEntry: any) => bannerEntry?.fields?.banner
        );

        setContentfulBanner(bannerContent);
      }
    });
  }, [sku]);

  useEffect(() => {
    let isMounted = true;
    const cancelTokenSource = getCancelTokenSource();

    if (isMounted) {
      getContentfulBanner();
    }

    Promise.all([
      getProduct(cancelTokenSource),
      setPriceTagList(cancelTokenSource),
      setFavouriteLists(cancelTokenSource),
    ]).catch((err) => {
      if (!axios.isCancel(err)) {
        message.error(messageData.error.unexpected);
      }

      requestCatchHandler(err);
    });

    return () => {
      isMounted = false;
      cancelTokenSource.cancel();
    };
  }, [sku, navigate, getProduct, getContentfulBanner]);

  return (
    <>
      <TrackingHelmet
        title={pageTitles.productDetail}
        suffix={pageTitleSuffix}
      />

      {isLoading && <LoaderComponent />}

      {!isInProductDetailModal && <ShopCategoryNavigation />}

      {!isLoading && productData && (
        <>
          {!isInProductDetailModal && (
            <Layout className="container-layout container-layout--inner">
              <nav className="flex flex-row">
                <BackButton withBreadcrumbs />
                <Breadcrumbs breadcrumbs={breadcrumbData} />
              </nav>
            </Layout>
          )}

          <Layout className="container-layout container-layout--inner productDetail">
            <Row gutter={12} className="justify-end">
              <Col xs={12} md={{ span: 7, offset: 5 }}>
                <header className="productDetailHeader">
                  <ProductAddToFavouritesListButton sku={sku} />

                  <ProductAddToPriceTagListButton sku={sku} />
                </header>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ProductDetailView
                  deliveryDate={deliveryDate}
                  productData={productData}
                />
              </Col>
            </Row>
            {!isInWeekplannerProductDetailModal && (
              <Row>
                <Col span={12}>
                  <ProductSlider
                    attributes={productData?.attributes}
                    referenceSku={sku}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <HrDivider spacingTop="xl" spacingBottom="xl" size={2} />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <ProductDetailInfo
                  attributes={productData?.attributes}
                  ingredientsLegend={ingredientsLegend}
                  herbalNotice={herbalNotice}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <HrDivider spacingTop="xl" spacingBottom="xl" size={2} />
              </Col>
            </Row>
            {hasDownloads && (
              <Row>
                <Col xs={12}>
                  <ProductDetailDownloads sku={sku} />
                </Col>
              </Row>
            )}
            {hasDownloads && (
              <Row>
                <Col xs={12}>
                  <HrDivider spacingTop="xl" spacingBottom="xl" size={2} />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} lg={{ span: 8, offset: 2 }}>
                <ProductVideo
                  attributes={productData?.attributes}
                  name={productData?.name}
                />
              </Col>
            </Row>
            {contentfulBanner?.length > 0 && (
              <Row>
                <Col span={12}>
                  {contentfulBanner.map((bannerFields: any) => (
                    <NewsBanner
                      key={bannerFields?.sys?.id}
                      banner={bannerFields?.fields}
                    />
                  ))}
                </Col>
              </Row>
            )}
          </Layout>
        </>
      )}

      {!isLoading && !productData && (
        <Layout className="container-layout container-layout--inner">
          <Result title="Es konnte kein Produkt mit diesen Kritierien gefunden werden!" />
        </Layout>
      )}

      <ButtonBackToTop />
    </>
  );
};

export default ProductDetail;
