import React from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";

import isExternalUrl from "../../../utils/isExternalUrl";
import getRelativeFromAbsoluteUrl from "../../../utils/getRelativeFromAbsoluteUrl";

interface AdLinkProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  onClick?: () => void;
}

const AdLink: React.FC<AdLinkProps> = (props: AdLinkProps) => {
  const { children, href, className, onClick } = props;

  return (
    <>
      {isExternalUrl(href) ? (
        <a
          href={href}
          className={clsx("ad-link", className)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            if (typeof onClick === "function") {
              onClick();
            }
          }}
        >
          {children}
        </a>
      ) : (
        <Link
          to={getRelativeFromAbsoluteUrl(href)}
          className={clsx("ad-link", className)}
          onClick={() => {
            if (typeof onClick === "function") {
              onClick();
            }
          }}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export default AdLink;
