import moment, { Moment } from "moment";

const getAdvertisementText = (
  advertisement: any,
  referenceDate: Moment,
  nextMonthCategory: boolean,
  view: string
) => {
  let parsedAdvertisement: any = null;

  try {
    parsedAdvertisement =
      typeof advertisement === "object"
        ? advertisement
        : JSON.parse(advertisement);
  } catch (e) {
    return advertisement;
  }

  if (!Array.isArray(parsedAdvertisement)) {
    parsedAdvertisement = [parsedAdvertisement];
  }
  const activeAdvertisement: object =
    (parsedAdvertisement as Array<object>).find((obj: object) => {
      const advertisementPeriod = Object.values(obj)?.[0]?.[0] || {};
      const { from: activeFrom, to: activeTo } = advertisementPeriod;
      const isReferenceDateInActiveDateRange = referenceDate.isBetween(
        moment(activeFrom),
        moment(activeTo),
        "day",
        "[]"
      );

      if (view !== "weekplanner") {
        return isReferenceDateInActiveDateRange;
      }

      return (
        isReferenceDateInActiveDateRange ||
        moment(activeFrom).week() === referenceDate.week() ||
        moment(activeTo).week() === referenceDate.week()
      );
    }) || {};
  if (nextMonthCategory) {
    if (Object.keys(activeAdvertisement).length) {
      return {
        value: Object.keys(activeAdvertisement)?.[0],
        from: Object.values(activeAdvertisement)?.[0]?.[0]?.from,
        to: Object.values(activeAdvertisement)?.[0]?.[0]?.to,
      };
    }
    const nextAdvertisement: object = parsedAdvertisement[0];
    return {
      value: Object.keys(nextAdvertisement)?.[0],
      from: Object.values(nextAdvertisement)?.[0]?.[0]?.from,
      to: Object.values(nextAdvertisement)?.[0]?.[0]?.to,
    };
  }
  return { value: Object.keys(activeAdvertisement)?.[0] as string } || "";
};

export default getAdvertisementText;
