import React, { useRef, useState } from "react";

import clsx from "clsx";
import { Spin, Tooltip } from "antd";
import axios from "axios";

import useCancelAxiosOnUnmount from "../../hooks/useCancelAxiosOnUnmount";
import requestCatchHandler from "../../api/requestCatchHandler";
import getProductDetails from "../../api/products/getProductDetails";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../api/getCancelTokenSource";
import { ProductDetails } from "../../types/productData";

interface ProductInfoFollowUpBadgeProps {
  sku: string;
  reason: string;
  view: "list" | "weekplanner" | "detail";
  isWeekplannerInSimpleMode: boolean;
  isSmallWeekplanner: boolean;
  className?: string;
}

const ProductInfoFollowUpBadge: React.FC<ProductInfoFollowUpBadgeProps> = (
  props: ProductInfoFollowUpBadgeProps
) => {
  const {
    sku,
    reason,
    view,
    isWeekplannerInSimpleMode,
    isSmallWeekplanner,
    className,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<ProductDetails["name"]>("");

  const cancelTokenSource = useRef(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const handleVisibleChange = (visible: boolean) => {
    if (!visible) {
      return;
    }

    setIsLoading(true);

    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    getProductDetails({
      sku,
      cancelTokenSource: cancelTokenSource.current,
    })
      .then((productDetails: ProductDetails) => {
        setIsLoading(false);
        setName(productDetails?.name || "");
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
        }
        requestCatchHandler(error);
      });
  };

  return (
    <Tooltip
      onVisibleChange={handleVisibleChange}
      trigger={["hover", "click"]}
      title={
        <>
          {isLoading ? (
            <Spin tip="Lade Daten..." />
          ) : (
            <>
              <div>Vorgängerartikelnummer: {sku || "-"}</div>
              <div>Vorgängername: {name || "-"}</div>
              <div>Auslistungsgrund Vorgänger: {reason || "-"}</div>
            </>
          )}
        </>
      }
      destroyTooltipOnHide
    >
      <div
        className={clsx(
          "overlayInfoBadge productFollowupBadge",
          `${view}InfoBadge`,
          isSmallWeekplanner &&
            !isWeekplannerInSimpleMode &&
            view === "weekplanner" &&
            "badge__small-screen",
          className
        )}
      >
        <p>FOLGE</p>
      </div>
    </Tooltip>
  );
};

export default ProductInfoFollowUpBadge;
