import React from "react";
import { Layout } from "antd";
import Header from "../navigation/Header";
import Footer from "../navigation/Footer";
import MobileFooter from "../navigation/MobileFooter";

interface Props {
  children: any;
}

function ProtectedPageLayoutWrapper({ children }: Props) {
  return (
    <Layout className="container-layout">
      <Header />

      <Layout.Content className="container-layout container-layout--content">
        {children}
      </Layout.Content>

      <Layout.Footer className="container-layout container-layout--footer">
        <Footer />
        <MobileFooter />
      </Layout.Footer>
    </Layout>
  );
}

export default ProtectedPageLayoutWrapper;
