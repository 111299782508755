import React from "react";

import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Radio, RadioChangeEvent } from "antd";
import moment from "moment";

import { Entry } from "contentful";
import { ContentfulImpulsaktionEntry } from "../../../../types/contentfulImpulsaktion";

interface ImpulseTopMenuProps {
  impulsaktionen: Entry<ContentfulImpulsaktionEntry>[];
  activeImpulsaktionIndex: number;
  onChange: (e: RadioChangeEvent) => void;
  className?: string;
}

const ImpulseTopMenu: React.FC<ImpulseTopMenuProps> = (
  props: ImpulseTopMenuProps
) => {
  const { impulsaktionen, activeImpulsaktionIndex, onChange, className } =
    props;

  const dispatch = useDispatch();

  if (!impulsaktionen || !impulsaktionen?.length) {
    return null;
  }

  return (
    <div className={clsx("impulse-top-menu", className)}>
      <Radio.Group value={activeImpulsaktionIndex} onChange={onChange}>
        {impulsaktionen?.map((impulsaktion, i: number) => (
          <Radio.Button
            key={impulsaktion?.sys?.id}
            value={i}
            onClick={() => {
              dispatch({
                type: "impulse/set-active-index",
                payload: {
                  activeIndex: i,
                },
              });
            }}
          >
            {moment(impulsaktion?.fields?.start_date).format("MMMM")}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default ImpulseTopMenu;
