import moment from "moment";

const isNewCategory = (createdAt: string): boolean => {
  if (!createdAt) {
    return false;
  }

  return moment(createdAt).isAfter(moment().subtract(7, "days"));
};

export default isNewCategory;
