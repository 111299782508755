import React from "react";

import clsx from "clsx";

import getDeliveryDateBasedAttributes from "../../../../../product/getDeliveryDateBasedAttributes";
import getDefaultImageUrlSet from "../../../../../../utils/getDefaultImageUrlSet";
import {
  ProductAttributes,
  ProductImage,
  ProductTitle,
} from "../../../../../product";
import { ProductData } from "../../../../../../types/productData";

interface QuickOrderProductTileProps {
  deliveryDate: ProductData["deliveryDate"];
  productData: ProductData;
  className?: string;
}

const QuickOrderProductTile: React.FC<QuickOrderProductTileProps> = (
  props: QuickOrderProductTileProps
) => {
  const { deliveryDate, productData, className } = props;

  const { name, attributes, imageSets, sku, availabilities, prices } =
    productData;

  const { isUnavailable, nextAvailability } = getDeliveryDateBasedAttributes({
    deliveryDate,
    availabilities,
    prices,
  });

  return (
    <div className={clsx("quick-order-product-tile", className)}>
      <ProductImage
        imageSet={getDefaultImageUrlSet(imageSets)}
        className={clsx({ isUnavailable: isUnavailable && !nextAvailability })}
        sku={sku}
      />
      <div>
        <ProductTitle
          attributes={attributes}
          availabilities={availabilities}
          deliveryDate={deliveryDate}
          sku={sku}
          title={name}
          titleClassName={clsx({ isUnavailable })}
        />
        <ProductAttributes
          attributes={attributes}
          deliveryDate={deliveryDate}
          sku={sku}
        />
      </div>
    </div>
  );
};

export default QuickOrderProductTile;
