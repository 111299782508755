import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import axios, { CancelTokenSource } from "axios";
import { Col, Modal, Row } from "antd";
import { useSelector } from "react-redux";

import useCancelAxiosOnUnmount from "../../../../hooks/useCancelAxiosOnUnmount";
import renderContentfulDocument from "../../../../utils/renderContentfulDocument";
import getCancelTokenSource from "../../../../api/getCancelTokenSource";
import getCartDeliveryDateSlice from "../../../../state/actions/getCartDeliveryDate";
import axiosWithAuth from "../../../../api/axiosWithAuth";
import getCatalogSearchAttributesData from "../../../../api/products/getCatalogSearchAttributesData";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import Picture from "../../../picture/Picture";
import ProductTileWithModal from "../../../product/ProductTile/ProductTileWithModal";
import { getProductsEndpoint } from "../../../../api/endpoints";
import { sortArrayOfObjectsByObjectKeyOrderOfArrayWithNotFoundLast as sortProducts } from "../../../../utils/sorting";
import { ReactComponent as Info } from "../../../../static/svg/info.svg";
import {
  ContentfulWebshopProductInformationEntry,
  PdfAttachment,
} from "../../../../types/contentfulWebshopProductInformationEntry";
import { ProductData } from "../../../../types/productData";

interface WebshopProductInformationModalProps {
  productInformation: ContentfulWebshopProductInformationEntry;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const WebshopProductInformationModal: React.FC<
  WebshopProductInformationModalProps
> = (props: WebshopProductInformationModalProps) => {
  const { productInformation, isVisible, setIsVisible } = props;

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const deliveryDate = useSelector(getCartDeliveryDateSlice);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productData, setProductData] = useState<ProductData[]>(null);

  const sortedProductData = useMemo(
    () =>
      productData?.sort((a, b) =>
        sortProducts(a, b, "sku", productInformation?.productSkus)
      ),
    [productData, productInformation?.productSkus]
  );

  const loadProductData = useCallback(() => {
    axiosWithAuth()
      .get(
        getProductsEndpoint({
          deliveryDates: deliveryDate,
          sku: productInformation.productSkus?.join("+"),
        }),
        { cancelToken: cancelTokenSource.current?.token }
      )
      .then((response) => {
        const { concreteProducts } = getCatalogSearchAttributesData(
          response?.data?.data
        );
        setProductData(concreteProducts || []);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
        }
        requestCatchHandler(error);
      });
  }, [productInformation?.productSkus, deliveryDate]);

  useEffect(() => {
    const shouldLoadProductData =
      !isLoading &&
      productData === null &&
      isVisible &&
      Array.isArray(productInformation?.productSkus) &&
      productInformation.productSkus.length > 0;

    if (shouldLoadProductData) {
      loadProductData();
    }
  }, [
    productInformation?.productSkus,
    isVisible,
    productData,
    deliveryDate,
    isLoading,
    loadProductData,
  ]);

  return (
    <Modal
      visible={isVisible}
      title={productInformation.headline}
      okText="Schließen"
      onOk={() => setIsVisible(false)}
      onCancel={() => setIsVisible(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ className: "button buttonPrimary button--rounded" }}
      destroyOnClose
      wrapClassName="webshop-product-information-modal"
      width={1000}
    >
      <Row gutter={[20, 10]} className="mt-xl">
        <Col xs={12} md={3}>
          <Picture
            imageScreenSm={`${productInformation.imageDesktopUrl}?fit=thumb&w=400&h=400&fm=webp&q=80`}
            imageScreenMd={`${productInformation.imageDesktopUrl}?fit=thumb&w=200&h=200&fm=webp&q=60`}
          />
        </Col>

        <Col xs={12} md={9}>
          <h4>{productInformation.headline}</h4>

          <div className="webshop-product-information-modal__body">
            {renderContentfulDocument(productInformation.content)}
          </div>

          {!!productInformation.pdfFiles &&
            productInformation.pdfFiles.length > 0 && (
              <div className="webshop-product-information-modal__attachments">
                {productInformation.pdfFiles.map(
                  (attachment: PdfAttachment, i: number) => (
                    <a
                      key={`attachment_${i}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={attachment.downloadUrl}
                      download
                      type="button"
                    >
                      <Info />
                      <span>{attachment.fileName}</span>
                    </a>
                  )
                )}
              </div>
            )}
        </Col>

        {!isLoading &&
          Array.isArray(sortedProductData) &&
          sortedProductData.length > 0 && (
            <Col span={12} className="mt-l">
              {sortedProductData.map((item: ProductData) => (
                <ProductTileWithModal
                  key={item.sku}
                  deliveryDate={deliveryDate}
                  productData={item}
                />
              ))}
            </Col>
          )}
      </Row>
    </Modal>
  );
};

export default WebshopProductInformationModal;
