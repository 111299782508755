import React from "react";

import { useSelector } from "react-redux";
import { Col, Row } from "antd";

import { isTokenExpired } from "../../utils/authTokens";
import BusinessUnitModal from "../molecules/BusinessUnitModal";
import FooterLegalLinks from "../navigation/FooterLegalLinks";
import LoaderComponent from "../atoms/LoaderComponent";
import LoginHeader from "./LoginHeader";
import LoginForm from "./LoginForm";
import TrackingHelmet from "../Matomo/TrackingHelmet";
import { pageTitleSuffix, pageTitles } from "../../appConfig";
import { RootState } from "../../types/rootState";

const Login: React.FC = () => {
  const { accessToken, expiresInMilliseconds } = useSelector(
    (state: RootState) => state?.auth || {}
  );
  const { businessUnits } = useSelector(
    (state: RootState) => state?.userData || {}
  );

  if (businessUnits && accessToken && !isTokenExpired(expiresInMilliseconds)) {
    return (
      <>
        <LoaderComponent spinning={false} />
        <BusinessUnitModal visible />
      </>
    );
  }

  return (
    <>
      <TrackingHelmet title={pageTitles.login} suffix={pageTitleSuffix} />

      <div className="login">
        <Row
          xs={12}
          md={3}
          className="login-header-row"
          gutter={[
            { xs: 8, sm: 16, md: 32, lg: 32 },
            { xs: 8, sm: 16, md: 32, lg: 32 },
          ]}
        >
          <Col xs={12} md={2}>
            <LoginHeader />
          </Col>
        </Row>
        <Row className="login-welcome">
          <Col xs={12}>
            <h1>
              HERZLICH&nbsp;
              <br className="login-welcome-break" />
              WILLKOMMEN!
            </h1>
          </Col>

          <Col xs={12}>
            <h2>IN IHREM WEILING-PORTAL</h2>
          </Col>
        </Row>
        <LoginForm />
        <FooterLegalLinks navClassName="login-footer-menu" />
      </div>
    </>
  );
};
export default Login;
