import { BaseSyntheticEvent } from "react";
import { Asset } from "contentful";

import { contentfulDownloadableFileExtensions } from "../appConfig";

const downloadContenfulFile = (
  e: BaseSyntheticEvent,
  file: Asset,
  target: "_blank" | "_self" = "_blank"
) => {
  e.preventDefault();

  if (!file?.fields?.file) {
    return;
  }

  const { fileName, url: fileUrl, contentType = "" } = file?.fields?.file || {};

  if (!fileUrl) {
    return;
  }

  const isDownloadableImage = contentfulDownloadableFileExtensions
    .map((extension) => `image/${extension}`.toLowerCase())
    .includes(contentType.toLowerCase());

  if (isDownloadableImage) {
    fetch(fileUrl)
      .then((image) => {
        image
          .blob()
          .then((imageBlog) => {
            const imageURL = URL.createObjectURL(imageBlog);
            const link = document.createElement("a");
            link.href = imageURL;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch();
      })
      .catch();
    return;
  }

  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;
  link.target = target;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadContenfulFile;
