import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import getPageOffset from "../../utils/getPageOffset";
import { itemsPerPage } from "../../appConfig";
import { stocktakingEndpoint } from "../endpoints";
import { GetStocktakingsAttributesResponse } from "../../types/stocktaking";

interface GetStocktakingProps {
  limit?: number;
  page?: number;
  cancelTokenSource: CancelTokenSource;
}

const getStocktakings = async (props: GetStocktakingProps) => {
  const {
    limit = itemsPerPage.stocktakings,
    page = 1,
    cancelTokenSource,
  } = props;

  const url = `${stocktakingEndpoint}?page[limit]=${limit}&page[offset]=${getPageOffset(
    page,
    limit
  )}`;

  return axiosWithAuth()
    .get(url, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response): Promise<any> | GetStocktakingsAttributesResponse => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }
      const { attributes } = response?.data?.data?.[0] || {};

      return attributes as GetStocktakingsAttributesResponse;
    });
};

export default getStocktakings;
