import React, { useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { areEqual } from "react-window";
import moment from "moment/moment";
import { CancelTokenSource } from "axios";

import getDeliveryDateBasedAttributes from "../../../../../../product/getDeliveryDateBasedAttributes";
import getCancelTokenSource from "../../../../../../../api/getCancelTokenSource";
import useDebounceUpdateItemQuantity from "../../../../../../../hooks/useDebounceUpdateItemQuantity";
import getCartErrorMessage from "../../../../../../../utils/getCartErrorMessage";
import WeekplannerItemUnavailableContent from "../WeekplannerItemUnavailableContent";
import ProductAlreadyOrderedBatch from "../../../../../../product/ProductAlreadyOrderedBatch";
import ProductPrices from "../../../../../../product/ProductPrices";
import { ProductCount } from "../../../../../../product";
import { productAttributes } from "../../../../../../../api/productAttributes";
import { messageData } from "../../../../../../../appConfig";
import { ProductData } from "../../../../../../../types/productData";

interface WeekplannerItemCartProps {
  cartId: string;
  deliveryDate: any;
  productData: ProductData;
  pastOrderedProduct?: any;
  showCurrentVolumePrice?: boolean;
  className?: string;
  showUnavailableHeader?: boolean;
}

const WeekplannerItemCart: React.FC<WeekplannerItemCartProps> = (
  props: WeekplannerItemCartProps
) => {
  const {
    cartId,
    deliveryDate,
    productData,
    pastOrderedProduct,
    showCurrentVolumePrice,
    className,
    showUnavailableHeader = true,
  } = props;

  const { attributes, availabilities, sku, prices, productAlternatives } =
    productData;

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.package]: productUnitMeasurement,
    [productAttributes.basePriceUnit]: basePriceUnit,
    [productAttributes.weighingArticle]: weighingArticle,
  } = attributes;

  const { isUnavailable, nextAvailability } = useMemo(
    () =>
      getDeliveryDateBasedAttributes({
        deliveryDate,
        prices,
        availabilities,
        productAlternatives,
      }),
    [deliveryDate, prices, availabilities, productAlternatives]
  );

  const { weekCarts } = useSelector((state: any) => state.weekplannerData);
  const currentCart = useMemo(
    () =>
      weekCarts
        ? weekCarts.find((cartEntry: any) => cartEntry.id === cartId)
        : null,
    [cartId, weekCarts]
  );

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());

  const [inputHasChanged, setInputHasChanged] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedNumber, setSelectedNumber] = useState<number>(0);

  const initialNumber = useMemo(() => {
    return Number(
      currentCart?.deliveryDateItems?.find(
        (cartEntry: any) => cartEntry.sku === sku
      )?.quantity || 0
    );
  }, [currentCart, sku]);

  const isUnavailableForDate = useMemo(
    () =>
      isUnavailable &&
      !moment(nextAvailability).isSameOrBefore(moment(deliveryDate)),
    [deliveryDate, isUnavailable, nextAvailability]
  );

  const setItemQuantity = useDebounceUpdateItemQuantity({
    initialQuantity: initialNumber,
    deliveryDate,
    cartId,
    sku,
    setIsLoading: () => {
      setInputHasChanged(true);
    },
    resolve: (response) => {
      setInputHasChanged(false);
      if (response.config.method === "delete") {
        setAlertMessage(
          "Das Produkt wurde für diesen Tag aus dem Warenkorb entfernt."
        );
      } else {
        const cartErrorMessage = getCartErrorMessage({
          response,
          sku,
        });

        if (cartErrorMessage) {
          setAlertMessage(cartErrorMessage.reason);
          setSelectedNumber(Number(cartErrorMessage.quantityAdjusted));
        }
      }
    },
    reject: (error) => {
      setInputHasChanged(false);

      setAlertMessage(
        error?.response?.data?.errors[0]?.detail ||
          messageData.error.unexpected.content
      );
    },
    cancelTokenSource: cancelTokenSource.current,
  });

  // updates input, triggers debounced server update
  const handleChange = (value: number) => {
    setSelectedNumber(value);
    setItemQuantity(value);
  };

  /**
   * updated initial number if redux change happened
   */
  useEffect(() => {
    setSelectedNumber(initialNumber);
  }, [initialNumber]);

  return (
    <div
      className={clsx(
        "weekplanner-item-cell",
        "weekplanner-item-cart",
        !!isUnavailableForDate && "weekplanner-item-cart--unavailable",
        className
      )}
    >
      {!!isUnavailableForDate && (
        <>
          <WeekplannerItemUnavailableContent
            deliveryDate={deliveryDate}
            nextAvailability={nextAvailability}
            productData={productData}
            showHeader={showUnavailableHeader}
          />
          <ProductAlreadyOrderedBatch
            count={pastOrderedProduct}
            className="mb-s"
          />
        </>
      )}

      {!isUnavailableForDate && (
        <>
          <ProductCount
            value={selectedNumber}
            highlightThreshold={0}
            handleChange={handleChange}
            alertMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            className="weekplannerCellProductCountInner"
            isLoading={inputHasChanged}
            pastOrderedProduct={pastOrderedProduct}
            useAddons
          />
          {!!selectedNumber && showCurrentVolumePrice && (
            <ProductPrices
              prices={prices}
              deliveryDate={deliveryDate}
              quantity={Number(
                selectedNumber + (pastOrderedProduct?.quantity || 0)
              )}
              basePriceQuantity={productUnitQuantity}
              basePriceUnit={basePriceUnit}
              productUnitMeasurement={productUnitMeasurement}
              weighingArticle={weighingArticle}
              sku={sku}
            />
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(WeekplannerItemCart, areEqual);
