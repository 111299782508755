import React, { useMemo } from "react";

import clsx from "clsx";
import { Col, Layout, Row } from "antd";

import useGetAllCartsCheapMetadata from "../../../hooks/useGetAllCartsCheapMetadata";
import useGetImpulsaktionen from "../../../hooks/useGetImpulsaktionen";
import { routePathNames, routeProductCategoryKeys } from "../../../appConfig";
import { OpenCartsWidget, QuickLinksWidget } from "../../molecules";
import { ContentBlock, PictureTile, QuickLink } from "../../atoms";
import Picture from "../../picture/Picture";
import HrDivider from "../../divider/Divider";
import MonthlyPromotion from "../../../static/images/monthly-promotion-@x1.jpg";
import MonthlyPromotionSmall from "../../../static/images/monthly-promotion-small-@x1.jpg";
import TrainingTeaser from "../../../static/images/teaser-training-@x1.jpg";
import ImpulseTeaser from "../../../static/images/impulse-teaser-@x1.jpg";

interface DashboardWidgetBlockProps {
  maxDetailedCarts?: number;
  maxCarts?: number;
  className?: string;
}

const DashboardWidgetBlock: React.FC<DashboardWidgetBlockProps> = (props) => {
  const { maxDetailedCarts = 3, maxCarts = 5, className } = props;

  const { isLoading, hasError, openCarts } =
    useGetAllCartsCheapMetadata(maxCarts);
  const { entries: impulsaktionen = [] } = useGetImpulsaktionen(1, 0);

  const openCartsWithDetails: any[] = useMemo(
    () => openCarts.slice(0, maxDetailedCarts),
    [maxDetailedCarts, openCarts]
  );
  const openCartsWithoutDetails: any[] = useMemo(
    () => openCarts.slice(maxDetailedCarts, maxCarts),
    [maxCarts, maxDetailedCarts, openCarts]
  );

  const showMoreOpenCartsAndTeasers = maxCarts > maxDetailedCarts;

  return (
    <Layout
      className={clsx(
        "dashboard-widget-block container-layout container-layout--inner",
        className
      )}
    >
      <Row>
        <Col xs={12} lg={6} className="open-cart-widget-col">
          <OpenCartsWidget
            openCarts={openCartsWithDetails}
            isLoading={isLoading}
            hasError={hasError}
            showCartDetails
          />
        </Col>

        <Col xs={12} lg={6} className="quick-links-widget-col">
          <QuickLinksWidget />
        </Col>

        {!!openCartsWithoutDetails.length && showMoreOpenCartsAndTeasers && (
          <Col xs={12} lg={6} className="other-open-cart-widget-col">
            <OpenCartsWidget
              openCarts={openCartsWithoutDetails}
              isLoading={isLoading}
              hasError={hasError}
              showHeader={false}
              showDividerOnTop
              className="other-open-cart-widget"
              footer={
                <PictureTile
                  title="Unsere aktuellen Aktionsprodukte"
                  overlayPosition="right"
                  picture={
                    <Picture
                      imageScreenXs={MonthlyPromotionSmall}
                      imageScreenSm={MonthlyPromotionSmall}
                    />
                  }
                  link={
                    <QuickLink
                      label="Entdecken"
                      href={`${routePathNames.products}${routePathNames.category}${routeProductCategoryKeys.promotion}`}
                      linkType="primary--outlined"
                    />
                  }
                />
              }
            />
          </Col>
        )}

        {!openCartsWithoutDetails.length && showMoreOpenCartsAndTeasers && (
          <Col xs={12} lg={6} className="other-teaser-widget-col">
            <ContentBlock showHeader={false} className="other-teaser-widget">
              <HrDivider size={2} />
              <Row>
                <Col xs={12}>
                  {impulsaktionen?.length > 0 ? (
                    <PictureTile
                      title="Jetzt die Impulse-Daten herunterladen"
                      picture={
                        <Picture
                          imageScreenXs={ImpulseTeaser}
                          imageScreenSm={ImpulseTeaser}
                        />
                      }
                      link={
                        <QuickLink
                          label="Zu den Impulsen"
                          href={`${routePathNames.impulse}`}
                          linkType="primary--outlined"
                        />
                      }
                    />
                  ) : (
                    <PictureTile
                      title="Unsere aktuellen Aktionsprodukte"
                      picture={
                        <Picture
                          imageScreenXs={MonthlyPromotion}
                          imageScreenSm={MonthlyPromotion}
                        />
                      }
                      link={
                        <QuickLink
                          label="Entdecken"
                          href={`${routePathNames.products}${routePathNames.category}${routeProductCategoryKeys.promotion}`}
                          linkType="primary--outlined"
                        />
                      }
                    />
                  )}
                </Col>
              </Row>
            </ContentBlock>
          </Col>
        )}

        {showMoreOpenCartsAndTeasers && (
          <Col xs={12} lg={6} className="teaser-widget-col">
            <ContentBlock showHeader={false} className="teaser-widget">
              <HrDivider size={2} />
              <Row>
                <Col xs={12}>
                  <PictureTile
                    title=""
                    picture={
                      <Picture
                        imageScreenXs={TrainingTeaser}
                        imageScreenSm={TrainingTeaser}
                      />
                    }
                    link={
                      <QuickLink
                        label="Jetzt kostenlos anmelden"
                        href="https://www.weiling-akademie.de/seminare-2024/detail?tx_wseminare_plg%5Baction%5D=show&tx_wseminare_plg%5Bcontroller%5D=Seminar&tx_wseminare_plg%5Bseminar%5D=171"
                        linkType="primary--outlined"
                      />
                    }
                  />
                </Col>
              </Row>
            </ContentBlock>
          </Col>
        )}
      </Row>
    </Layout>
  );
};

export default DashboardWidgetBlock;
