import React, { useMemo } from "react";

import { connect, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import clearStore from "../state/actions/clearStore";
import { isTokenExpired } from "../utils/authTokens";
import { routePathNames } from "../appConfig";
import { AuthToken } from "../state/reducers/authReducer";
import { RootState } from "../types/rootState";

interface ProtectedRouteTypes {
  children: React.ReactNode;
  auth: AuthToken;
}

/**
 * test if the user is logged in, otherwise redirect to the login page
 * @param children
 * @param auth
 * @constructor
 */
function ProtectedRoute({ children, auth }: ProtectedRouteTypes) {
  const { businessUnit } = useSelector((state: RootState) => state.userData);

  const isUnauthorized = useMemo(
    () =>
      !auth?.accessToken ||
      !businessUnit ||
      isTokenExpired(auth?.expiresInMilliseconds),
    [auth?.accessToken, businessUnit, auth?.expiresInMilliseconds]
  );

  if (isUnauthorized) {
    clearStore();
  }

  return (
    <>{isUnauthorized ? <Navigate to={routePathNames.login} /> : children}</>
  );
}

/**
 * redux connect function to pass state as a prop to component
 * here is just the auth reducer state passed down
 * @param auth {Record<string, any>}
 */
const mapStateToProps = ({ auth }: any) => ({ auth });

export default connect(mapStateToProps)(ProtectedRoute);
