import React from "react";

import { Tag } from "antd";
import moment from "moment";

import useGetSkuOrderData from "../../../hooks/useGetSkuOrderData";
import { fullDateFormat } from "../../../utils/dateFormats";
import { CheapCartMetadata } from "../../../types/cheapCartMetadata";
import { ProductData } from "../../../types/productData";
import { SkuOrderData } from "../../../types/productPreorderModal";

interface ProductPreorderTooltipProps {
  sku: ProductData["sku"];
  openCarts?: Array<CheapCartMetadata>;
  orders?: Record<string, number>;
}

const ProductPreorderModalInnerInfo: React.FC<ProductPreorderTooltipProps> = (
  props
) => {
  const { sku, openCarts = [], orders = {} } = props;

  const { skuOrderData } = useGetSkuOrderData({ openCarts, sku });

  const hasSkuOrderData =
    Array.isArray(skuOrderData) && skuOrderData.length > 0;

  const hasOrders = Object.keys(orders || {}).length > 0;

  if (!hasSkuOrderData && !hasOrders) {
    return null;
  }

  return (
    <div className="product-preorder-modal-inner-info">
      {hasSkuOrderData && (
        <div className="product-preorder-modal-inner-info--cart">
          <Tag>Warenkorb</Tag>
          <div>Dieser Artikel ist bereits in folgenden Warenkörben:</div>
          <ul className="list--unstyled">
            {skuOrderData.map((data: SkuOrderData) => {
              return (
                <li key={`cart-${data?.deliveryDate || ""}`}>
                  {`${moment(data.deliveryDate).format(fullDateFormat)}: ${
                    data?.quantity || "-"
                  }x`}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {hasOrders && (
        <div className="product-preorder-modal-inner-info--order">
          <Tag>Zulaufmenge</Tag>
          <div>
            Dieser Artikel wurde bereits zu folgenden Tagen bestellt und wird
            geliefert:
          </div>
          <ul className="list--unstyled">
            {Object.keys(orders).map((order: any, index) => {
              return (
                <li key={`order-${orders?.[order]}-${index}`}>
                  {`${moment(order).format(fullDateFormat)}: ${
                    orders?.[order] || "-"
                  }x`}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProductPreorderModalInnerInfo;
