import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Col, Layout, Row } from "antd";

import clsx from "clsx";
import getApiLevelHeader from "../../../utils/getApiLevelHeader";
import getUrlReferrerString from "../../../utils/getUrlReferrerString";
import requestCatchHandler from "../../../api/requestCatchHandler";
import PublicPageLayoutWrapper from "../PublicPageLayoutWrapper";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { apiLevel, statusEndpoint } from "../../../api/endpoints";
import { pageTitles, pageTitleSuffix, delays } from "../../../appConfig";
import MaintenanceVideo from "../../../static/video/maintenance_1920.mp4";

interface MaintenanceProps {
  className?: string;
}

const Maintenance: React.FC<MaintenanceProps> = (props: MaintenanceProps) => {
  const { className } = props;

  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlReferrer = getUrlReferrerString(search);

    const interval = setInterval(() => {
      if (!urlReferrer) {
        return;
      }

      axios
        .get(statusEndpoint)
        .then(({ headers }) => {
          const serverApiLevel = getApiLevelHeader(headers);

          if (serverApiLevel === apiLevel || apiLevel === "null") {
            navigate(urlReferrer);
            return;
          }

          if (serverApiLevel !== apiLevel) {
            window.location.reload();
          }
        })
        .catch(requestCatchHandler);
    }, delays.maintenance);

    return () => {
      clearInterval(interval);
    };
  }, [search, navigate]);

  return (
    <>
      <TrackingHelmet title={pageTitles.maintenance} suffix={pageTitleSuffix} />

      <PublicPageLayoutWrapper>
        <Layout
          className={clsx(
            "maintenance-page",
            "container-layout container-layout--inner",
            className
          )}
        >
          <div className="maintenance-page__wrapper">
            <video
              autoPlay
              loop
              controls={false}
              muted
              preload="auto"
              className="maintenance-page__wrapper__background"
            >
              <source src={MaintenanceVideo} type="video/mp4" />
            </video>

            <Row gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
              <Col span={12}>
                <div className="maintenance-page__wrapper__content">
                  <h1>
                    <span className="text-3xl">Fleißig</span>
                    <span>wie die Bienen</span>
                  </h1>

                  <h2 className="like-h3">
                    Das Weiling-Portal wird gerade aktualisiert.
                  </h2>

                  <p>
                    Wir sind gerade ganz besonders fleißig, damit Sie bald
                    wieder bestellen können.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Layout>
      </PublicPageLayoutWrapper>
    </>
  );
};

export default Maintenance;
