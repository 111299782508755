import React from "react";

import { useSelector } from "react-redux";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import clsx from "clsx";
import { Carousel, Col, Row } from "antd";
import { Entry } from "contentful";

import useGetAdWebshopDashboard from "../../../hooks/useGetAdWebshopDashboard";
import useMedia from "../../../hooks/useMedia";
import getCssVariable from "../../../utils/getCssVariable";
import Picture from "../../picture/Picture";
import { AdLink, ContentBlock, PictureTile, QuickLink } from "../../atoms";
import { RootState } from "../../../types/rootState";
import { ContentfulAdWebshopDashboardEntry } from "../../../types/contentfulAdWebshopDashboardEntry";

interface AdWebshopDashboardProps {
  className?: string;
}

const AD_TYPE = "ad webshop dashboard";

const AdWebshopDashboard: React.FC<AdWebshopDashboardProps> = (
  props: AdWebshopDashboardProps
) => {
  const { className } = props;

  const isSmallScreen = useMedia(
    `(max-width: ${getCssVariable("screen-md-max")})`
  );

  const { stockName } = useSelector((state: RootState) => state?.userData);

  const { isLoading, hasError, entries } = useGetAdWebshopDashboard({
    stockName,
  });

  const { trackEvent } = useMatomo();

  const adBanners = entries.map(
    (ad: Entry<ContentfulAdWebshopDashboardEntry>, i: number) => {
      const adName = ad?.fields?.internal_name || "";
      const adBannerUrl = ad?.fields?.banner?.fields?.file?.url;
      const adText = ad?.fields?.banner_text;
      const adUrl = ad?.fields?.banner_link;
      const adBanner = (
        <PictureTile
          title={null}
          picture={
            <Picture
              imageScreenSm={`${adBannerUrl}?fit=fill&f=top&r=10&fm=webp&q=40`}
              imageScreenXl={`${adBannerUrl}?fit=fill&f=top&r=10&fm=webp&q=80`}
            />
          }
          link={
            adUrl && adText ? (
              <QuickLink
                label={adText}
                href={adUrl}
                linkType="primary--outlined"
                onClick={() =>
                  trackEvent({
                    category: "ads",
                    action: `${AD_TYPE} click`,
                    name: adName,
                  })
                }
              />
            ) : null
          }
          className="ad-webshop-dashboard__picture-tile"
        />
      );

      return (
        <Col
          span={!isSmallScreen ? 3 : 12}
          key={`ad_webshop_dashboard_${i}`}
          onLoad={() =>
            trackEvent({
              category: "ads",
              action: `${AD_TYPE} impression`,
              name: adName,
            })
          }
        >
          {(adText || !adUrl) && adBanner}
          {!adText && adUrl && (
            <AdLink
              href={adUrl}
              onClick={() => {
                trackEvent({
                  category: "ads",
                  action: `${AD_TYPE} click`,
                  name: adName,
                });
              }}
            >
              {adBanner}
            </AdLink>
          )}
        </Col>
      );
    }
  );

  if (isLoading || !entries?.length || hasError) {
    return null;
  }

  return (
    <ContentBlock
      className={clsx("ad-webshop-dashboard", className)}
      title="Lieferantenaktionen"
    >
      <Row gutter={[20, 10]}>
        {!isSmallScreen && !isLoading && !hasError && !!entries && adBanners}
        {isSmallScreen &&
          !isLoading &&
          !hasError &&
          !!entries &&
          entries.length && (
            <Col span={12}>
              <Carousel>{adBanners}</Carousel>
            </Col>
          )}
      </Row>
    </ContentBlock>
  );
};

export default AdWebshopDashboard;
