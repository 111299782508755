import React, { useMemo } from "react";
import clsx from "clsx";
import { Layout, Row, Col, Spin, Result, Skeleton } from "antd";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { MenuOutlined } from "@ant-design/icons";
import ProductTileWithModal from "../product/ProductTile/ProductTileWithModal";
import { ChildProps } from "../products/ProductsViewWrapper";
import {
  messageData,
  routePathNames,
  locationSearchQueryParameter,
  pageTitles,
  pageTitleSuffix,
} from "../../appConfig";
import { fullDateFormat } from "../../utils/dateFormats";
import getCartDeliveryDateSlice from "../../state/actions/getCartDeliveryDate";
import {
  resetFilterStates,
  showClearAllFiltersComponent,
} from "../products/ProductsFilter/filterFunctions";
import ButtonBackToTop from "../buttons/ButtonBackToTop";
import { ProductData } from "../../types/productData";
import {
  BrandPortraitModal,
  FilterBar,
  SelectedFilters,
  Sidebar,
} from "../molecules";
import CategoryTree from "../molecules/CategoryTree";
import { FiltersSidebar } from "../organisms";
import { ReactComponent as FilterIcon } from "../../static/svg/filter_offen.svg";
import useMedia from "../../hooks/useMedia";
import getCssVariable from "../../utils/getCssVariable";
import useGetSelectedFiltersCount from "../../hooks/useGetSelectedFiltersCount";
import useGetFilterBarVisibleFilterNames from "../../hooks/useGetFilterBarVisibleFilterNames";
import { Filter } from "../../types/filters";
import { RootState } from "../../types/rootState";
import TrackingHelmet from "../Matomo/TrackingHelmet";

const ProductList: React.FunctionComponent<ChildProps> = ({
  showFilters,
  setShowFilters,
  productData,
  isLoading,
  webshopAdProductListing,
}: ChildProps) => {
  const navigate = useNavigate();
  const { visibleFilterNames } = useGetFilterBarVisibleFilterNames();
  const { selectedFiltersCount } = useGetSelectedFiltersCount();
  const { selectedFiltersCount: selectedMoreFiltersCount } =
    useGetSelectedFiltersCount(visibleFilterNames);
  const { pathname, search } = useLocation();
  const querySearch =
    queryString.parse(search)?.[locationSearchQueryParameter.searchTerm];

  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-md")})`
  );

  const deliveryDate = useSelector(getCartDeliveryDateSlice);

  const brandCode = productData[0]?.attributes?.herstellermarken_text;
  const isSearch = pathname.includes(routePathNames.search);
  const showClearAllFiltersButton = useMemo(
    () => showClearAllFiltersComponent(),
    []
  );

  const { herstellermarkenpreislistentextfuerausgabe: brandName } = useSelector(
    (state: RootState) => state.productsMetaData?.filters?.filterStates
  );

  // no results message
  const noResultsMessage = isSearch
    ? messageData.warning.noSearchResultsDetail.content.replace(
        /({{\w+}})/g,
        (match) => {
          const replacerVar = match.replace(/{|}/g, "");

          switch (replacerVar) {
            case "queryString":
              return String(querySearch);
            case "queryDate":
              return moment(deliveryDate).format(fullDateFormat);
            default:
              return "";
          }
        }
      )
    : messageData.warning.noProductResults.content;

  const { availableFilters, slots } = useSelector(
    (state: RootState) => state?.productsMetaData?.filters
  );

  const sidebarFilters: Filter[] = useMemo(() => {
    return availableFilters?.filter(
      (filter: Filter) => !visibleFilterNames?.includes(filter?.name)
    );
  }, [availableFilters, visibleFilterNames]);

  return (
    <>
      <TrackingHelmet title={pageTitles.productList} suffix={pageTitleSuffix} />

      <Layout>
        <Spin spinning={isLoading} size="large">
          <Row
            gutter={{ xs: 8, sm: 16, md: 10, lg: 10 }}
            justify="space-between"
          >
            <Col xs={0} md={3}>
              <Sidebar
                name={isSearch ? "search" : "product"}
                items={[
                  ...(!isSearch
                    ? [
                        {
                          title: "Kategorien",
                          content: <CategoryTree showHeader={false} />,
                          icon: <MenuOutlined />,
                          withAffix: true,
                        },
                      ]
                    : []),
                  {
                    title: "Weitere Filter",
                    content: (
                      <FiltersSidebar filters={sidebarFilters} slots={slots} />
                    ),
                    count: selectedMoreFiltersCount,
                    icon: <FilterIcon className="icon" />,
                  },
                ]}
                className="sidebar--product-list"
              />
            </Col>
            <Col xs={12} md={9}>
              <FilterBar
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                showSearchFilter
                showFiltersToggle={!browserIsDesktop}
                selectedFiltersCount={selectedFiltersCount}
                withAffix={browserIsDesktop}
              />

              <div className="filter-product-list-top">
                <Col xs={0} md={12} style={{ paddingRight: 0, paddingLeft: 0 }}>
                  <SelectedFilters />
                </Col>

                {brandName &&
                  brandName.length === 1 &&
                  brandCode &&
                  browserIsDesktop && (
                    <BrandPortraitModal
                      brandName={brandName?.[0] || ""}
                      brandCode={brandCode}
                    />
                  )}
              </div>

              <div
                className={clsx(
                  "productList",
                  !showFilters && !productData.length && "flex justify-center"
                )}
              >
                {productData &&
                  productData.map((product: ProductData, index: number) => {
                    const { sku } = product;

                    return (
                      <Skeleton key={sku} active loading={isLoading}>
                        <ProductTileWithModal
                          deliveryDate={deliveryDate}
                          productData={product}
                        />
                        {index === Math.floor(productData.length / 3) &&
                          webshopAdProductListing}
                      </Skeleton>
                    );
                  })}

                {!isLoading && !productData?.length && (
                  <Result
                    title={noResultsMessage}
                    extra={
                      showClearAllFiltersButton && (
                        <button
                          type="button"
                          className="buttonText buttonClearAllFilters"
                          onClick={() => resetFilterStates(navigate)}
                        >
                          Filter{" "}
                          <span className="text-underline">zurücksetzen</span>
                        </button>
                      )
                    }
                  />
                )}
              </div>
            </Col>
          </Row>
          <ButtonBackToTop />
        </Spin>
      </Layout>
    </>
  );
};

export default ProductList;
