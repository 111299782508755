import clsx from "clsx";
import React from "react";
import {
  ProductAttributes,
  ProductImage,
  ProductInfoPopUp,
  ProductInsignias,
  ProductTitle,
} from "../index";
import getDefaultImageUrlSet from "../../../utils/getDefaultImageUrlSet";
import ProductInfoVolumePricesPopup from "../ProductInfoVolumePricesPopup";
import { ProductData } from "../../../types/productData";
import { productAttributes } from "../../../api/productAttributes";
import ProductBasket from "../ProductBasket/ProductBasket";
import useStockForAttribute from "../../../hooks/useStockForAttribute";
import ProductInfoBadge from "../ProductInfoBadge";
import getDeliveryDateBasedAttributes from "../getDeliveryDateBasedAttributes";
import ProductAddToPriceTagListButton from "../ProductAddToPriceTagListButton";
import ProductAddToFavouritesListButton from "../ProductAddToFavouritesListButton";
import ProductShowOrderItemsButton from "../ProductShowOrderItemsButton";

interface Props {
  deliveryDate: ProductData["deliveryDate"];
  productData: ProductData;
  defaultQuantity?: number;
}

const ProductTileInner: React.FC<Props> = function ProductTileInner({
  deliveryDate,
  productData,
  defaultQuantity,
}: Props) {
  const { name, attributes, imageSets, sku, availabilities, prices } =
    productData;

  const { isUnavailable, volumePrices, strikePrice, nextAvailability } =
    getDeliveryDateBasedAttributes({
      deliveryDate,
      availabilities,
      prices,
    });

  const advertisement = useStockForAttribute(attributes, "advert");
  const advertisementLink = useStockForAttribute(attributes, "advertLink");

  const {
    [productAttributes.unitQuantity]: productUnitQuantity,
    [productAttributes.quality]: qualitySign,
    [productAttributes.qualityText]: qualityText,
    [productAttributes.bioIcon]: bioladenLogo,
    [productAttributes.bioFairIcon]: bioFairLogo,
    [productAttributes.isNewArticle]: isNewArticle,
    [productAttributes.deprecatedSku]: deprecatedSku,
    [productAttributes.articleStb]: articleStb,
    [productAttributes.weighingArticle]: weighingArticle,
    [productAttributes.articlePoster]: isArticlePosterAvailable,
  } = attributes;

  const deprecatedReason = useStockForAttribute(attributes, "deprecatedReason");

  const icons = String(qualitySign).split(" ");

  return (
    <>
      <ProductImage
        imageSet={getDefaultImageUrlSet(imageSets)}
        className={clsx({ isUnavailable: isUnavailable && !nextAvailability })}
        sku={sku}
      />

      <ProductInfoBadge
        view="list"
        advertisement={advertisement}
        isNewArticle={isNewArticle}
        deprecatedSku={deprecatedSku}
        deprecatedReason={deprecatedReason}
        isArticlePosterAvailable={isArticlePosterAvailable}
        advertisementLink={advertisementLink}
      />

      <ProductTitle
        attributes={attributes}
        availabilities={availabilities}
        deliveryDate={deliveryDate}
        sku={sku}
        title={name}
        titleClassName={clsx({ isUnavailable })}
      />

      <ProductInsignias
        width={{ xs: 12 }}
        icons={icons}
        bioladenLogo={bioladenLogo}
        bioladenFairLogo={bioFairLogo}
        qualityText={qualityText}
        steinofenbaeckerLogo={articleStb}
      />

      <ProductAttributes
        attributes={attributes}
        deliveryDate={deliveryDate}
        sku={sku}
      />

      <div className="productInfoIcons">
        <ProductInfoPopUp icons={icons} attributes={attributes} sku={sku} />

        <ProductAddToFavouritesListButton sku={sku} />

        <ProductAddToPriceTagListButton sku={sku} />

        <ProductShowOrderItemsButton sku={sku} className="productInfoIcon" />

        {volumePrices?.length > 0 && (
          <ProductInfoVolumePricesPopup
            volumePrices={volumePrices}
            basePriceQuantity={
              weighingArticle === "1" ? 1 : productUnitQuantity
            }
            className={clsx(
              "hidden-lg-up productInfoIcon",
              strikePrice > 0 && "hasStrikePriceVolumePrices"
            )}
          />
        )}
      </div>

      <ProductBasket
        productData={productData}
        deliveryDate={deliveryDate}
        defaultQuantity={defaultQuantity}
        view="productList"
      />
    </>
  );
};

export default ProductTileInner;
