import { CancelTokenSource } from "axios";
import { getAlreadyOrderedProductsEndpoint } from "../endpoints";
import axiosWithAuth from "../axiosWithAuth";

const getAlreadyOrdered = async (cancelTokenSource: CancelTokenSource) => {
  const url = getAlreadyOrderedProductsEndpoint;

  return axiosWithAuth()
    .get(url, {
      cancelToken: cancelTokenSource.token,
    })
    .then((res) => {
      const response = res?.data?.data[0]?.attributes;
      const { version, orderItemIndex } = response;

      if (!version) {
        return null;
      }

      const businessUnitKey = orderItemIndex?.companyBusinessUnitKey || null;

      const alreadyOrderedSku =
        orderItemIndex?.orderItemRecords &&
        Object.assign(
          {},
          ...orderItemIndex.orderItemRecords.map(
            ({
              sku,
              orderItemSkuRecords,
            }: {
              sku: any;
              orderItemSkuRecords: any;
            }) => ({
              [sku]: Object.assign(
                {},
                ...orderItemSkuRecords.map(
                  ({
                    deliveryDate,
                    quantity,
                  }: {
                    deliveryDate: any;
                    quantity: any;
                  }) => ({
                    [deliveryDate]: quantity,
                  })
                )
              ),
            })
          )
        );

      return {
        businessUnitKey,
        version,
        orderItemIndex: alreadyOrderedSku || {},
      };
    });
};

export default getAlreadyOrdered;
