import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";

import { brandInfoEndpoint } from "../endpoints";
import { BrandInfo } from "../../types/brandInfo";

interface GetBrandInfoProps {
  brandCode: string;
  cancelTokenSource: CancelTokenSource;
}

const getBrandInfo = async (props: GetBrandInfoProps) => {
  const { brandCode, cancelTokenSource } = props;

  return axiosWithAuth()
    .get(`${brandInfoEndpoint}?code=${brandCode}`, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response) => {
      if (response?.status !== 200) {
        return Promise.reject(response);
      }

      if (!response?.data?.data?.[0]) {
        return null;
      }

      const { description = "", imageUrl = "" } =
        response?.data?.data[0]?.attributes || {};

      const brandInfo: BrandInfo = {
        brandName: "",
        brandCode,
        brandText: description,
        brandLogo: imageUrl ? `${imageUrl}XL` : imageUrl,
      };

      return brandInfo;
    });
};

export default getBrandInfo;
