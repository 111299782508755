import React from "react";

import clsx from "clsx";
import { Tooltip } from "antd";
import moment, { Moment } from "moment";

import useGetSkuOrderData from "../../../hooks/useGetSkuOrderData";
import { apiDateFormat } from "../../../utils/dateFormats";
import { CheapCartMetadata } from "../../../types/cheapCartMetadata";
import { ProductData } from "../../../types/productData";
import { SkuOrderData } from "../../../types/productPreorderModal";

interface ProductPreorderCalenderCellTooltipProps {
  sku: ProductData["sku"];
  cellDate: Moment;
  openCarts?: CheapCartMetadata[];
  orders?: Record<string, number>;
}

const ProductPreorderModalInnerCalenderCellTooltip: React.FC<
  ProductPreorderCalenderCellTooltipProps
> = (props) => {
  const { sku, cellDate, openCarts = [], orders = {} } = props;

  const { skuOrderData } = useGetSkuOrderData({ openCarts, sku });

  const skuOrderDataForDate = skuOrderData?.find((data: SkuOrderData) =>
    moment(cellDate).isSame(data?.deliveryDate)
  );

  const skuAlreadyOrderedQuantityForDate =
    orders?.[cellDate.format(apiDateFormat)];

  if (!skuOrderDataForDate && !skuAlreadyOrderedQuantityForDate) {
    return <div className="ant-picker-cell-inner">{cellDate.date()}</div>;
  }

  return (
    <Tooltip
      title={
        <>
          {skuOrderDataForDate && (
            <div>{`Warenkorb: ${skuOrderDataForDate?.quantity}x`}</div>
          )}
          {skuAlreadyOrderedQuantityForDate && (
            <div>{`Zulaufmenge: ${skuAlreadyOrderedQuantityForDate}x`}</div>
          )}
        </>
      }
    >
      <div
        className={clsx(
          "ant-picker-cell-inner",
          skuOrderDataForDate &&
            skuAlreadyOrderedQuantityForDate &&
            "picker-cell--cart-and-order",
          skuOrderDataForDate &&
            !skuAlreadyOrderedQuantityForDate &&
            "picker-cell--cart-only",
          !skuOrderDataForDate &&
            skuAlreadyOrderedQuantityForDate &&
            "picker-cell--order-only"
        )}
      >
        {cellDate.date()}
      </div>
    </Tooltip>
  );
};

export default ProductPreorderModalInnerCalenderCellTooltip;
