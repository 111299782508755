import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { Col, Layout, Row } from "antd";

import getTermsAndConditions from "../../../api/contentful/getTermsAndConditions";
import handleError from "../../../utils/handleError";
import renderContentfulDocument from "../../../utils/renderContentfulDocument";
import PublicPageLayoutWrapper from "../PublicPageLayoutWrapper";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";

interface TermsProps {
  className?: string;
}

const Terms: React.FC<TermsProps> = (props: TermsProps) => {
  const { className } = props;

  const [contentfulData, setContentfulData] = useState<any>({});

  useEffect(() => {
    getTermsAndConditions()
      .then((tacTextContent: any) => {
        if (tacTextContent?.fields) {
          setContentfulData({ ...tacTextContent.fields });
        }

        return tacTextContent;
      })
      .catch(handleError);
  }, []);

  return (
    <>
      <TrackingHelmet
        title={pageTitles.termsAndConditions}
        suffix={pageTitleSuffix}
      />

      <PublicPageLayoutWrapper>
        <Layout
          className={clsx("terms-page", "container-layout--inner", className)}
        >
          <Row>
            <Col xs={12} lg={{ span: 8, offset: 2 }}>
              {contentfulData?.content &&
                renderContentfulDocument(contentfulData.content)}
            </Col>
          </Row>
        </Layout>
      </PublicPageLayoutWrapper>
    </>
  );
};

export default Terms;
