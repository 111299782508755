const isExternalUrl = (url: string, base = "portal.weiling.de"): boolean => {
  try {
    const urlObj = new URL(url);

    return (
      !!urlObj?.protocol && !urlObj?.hostname?.toLowerCase()?.includes(base)
    );
  } catch (error) {
    return false;
  }
};

export default isExternalUrl;
