import moment from "moment";
import { Entry } from "contentful";

import getContentfulContentType from "./getContentfulContentType";
import { contentfulContentTypes } from "../../appConfig";
import { contentfulDateFormat } from "../../utils/dateFormats";
import stockNameMapping from "../../utils/stockNameMapping";
import { ContentfulWebshopTeaserEntry } from "../../types/contentfulWebshopTeaserEntry";
import { StockType } from "../../types/stock";

interface WebshopDashboardTeaserParams {
  limit: number;
  skip: number;
  displayPosition?: "Oben" | "Unten";
  stockName?: StockType;
}

interface WebshopDashboardTeaserResponse {
  items: ContentfulWebshopTeaserEntry[];
  total: number;
}

/**
 * @param limit
 * @param skip
 * @param displayPosition
 * @param stockName
 *
 * @return Promise<any>
 */
const getWebshopDashboardTeaser = async ({
  limit,
  skip,
  displayPosition = "Oben",
  stockName = "HL",
}: WebshopDashboardTeaserParams): Promise<WebshopDashboardTeaserResponse> =>
  getContentfulContentType({
    ...contentfulContentTypes.webshopDashboardTeaser,
    limit,
    skip,
    order: "-fields.publish_date,-sys.createdAt",
    inclusion: {
      "fields.display_position[in]": displayPosition,
      "fields.publish_date[lte]": moment().format(contentfulDateFormat),
      "fields.stock_name[in]": `Alle,${stockNameMapping?.[stockName] || ""}`,
    },
    withTotal: true,
  }).then((response: any) => {
    if (!response) {
      return Promise.reject(response);
    }
    return {
      items:
        response.items?.map((webshopTeaserEntry: Entry<any>) => {
          const {
            image_desktop: imageDesktop,
            image_mobile: imageMobile,
            link,
            publish_date: publishDate,
            call_to_action: callToAction,
          } = webshopTeaserEntry.fields;

          return {
            id: webshopTeaserEntry.sys.id,
            imageDesktopUrl: imageDesktop?.fields?.file?.url || "",
            imageMobileUrl: imageMobile?.fields?.file?.url || "",
            link,
            publishDate,
            callToAction,
          } as ContentfulWebshopTeaserEntry;
        }) || [],
      total: response.total,
    } as WebshopDashboardTeaserResponse;
  });

export default getWebshopDashboardTeaser;
