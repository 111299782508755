import { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { Entry } from "contentful";

import getWebshopCategoryDownloads from "../api/contentful/getWebshopCategoryDownloads";
import handleError from "../utils/handleError";
import { ContentfulWebshopCategoryDownloadEntry } from "../types/contentfulWebshopCategoryDownloadEntry";
import { StockType } from "../types/stock";

interface GetWebshopCategoryDownloadsProps {
  categoryIds: string;
  stockName: StockType;
  fallbackCategoryIds?: string;
  limit?: number;
}

const useGetWebshopCategoryDownloads = (
  props: GetWebshopCategoryDownloadsProps
) => {
  const { categoryIds, stockName, limit = 1 } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<
    Entry<ContentfulWebshopCategoryDownloadEntry>[]
  >([]);
  const [total, setTotal] = useState<number>(null);

  const fetchCategoryDownload = useCallback(async () => {
    if (!categoryIds) {
      return;
    }

    setIsLoading(true);

    getWebshopCategoryDownloads({
      categoryIds,
      stockName,
    })
      .then((response) => {
        const categoryIdsForSorting = categoryIds.split(",");
        const items =
          response?.items
            ?.sort(
              (
                a: Entry<ContentfulWebshopCategoryDownloadEntry>,
                b: Entry<ContentfulWebshopCategoryDownloadEntry>
              ) => {
                return (
                  categoryIdsForSorting.indexOf(a?.fields?.category) -
                  categoryIdsForSorting.indexOf(b?.fields?.category)
                );
              }
            )
            ?.slice(0, limit) || [];
        setEntries(items);
        setTotal(items?.length || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [categoryIds, limit, stockName]);

  useEffect(() => {
    fetchCategoryDownload().then();
  }, [fetchCategoryDownload]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetWebshopCategoryDownloads;
