import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { Col, Layout, Row } from "antd";

import getPrivacy from "../../../api/contentful/getPrivacy";
import handleError from "../../../utils/handleError";
import renderContentfulDocument from "../../../utils/renderContentfulDocument";
import PublicPageLayoutWrapper from "../PublicPageLayoutWrapper";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import { pageTitles, pageTitleSuffix } from "../../../appConfig";

interface PrivacyProps {
  className?: string;
}

const Privacy: React.FC<PrivacyProps> = (props: PrivacyProps) => {
  const { className } = props;

  const [contentfulData, setContentfulData] = useState<any>({});

  useEffect(() => {
    getPrivacy()
      .then((tacTextContent: any) => {
        if (tacTextContent?.fields) {
          setContentfulData({ ...tacTextContent.fields });
        }

        return tacTextContent;
      })
      .catch(handleError);
  }, []);

  return (
    <>
      <TrackingHelmet title={pageTitles.privacy} suffix={pageTitleSuffix} />

      <PublicPageLayoutWrapper>
        <Layout
          className={clsx("privacy-page", "container-layout--inner", className)}
        >
          <Row>
            <Col xs={12} lg={{ span: 8, offset: 2 }}>
              {contentfulData?.content &&
                renderContentfulDocument(contentfulData.content)}
            </Col>
          </Row>
        </Layout>
      </PublicPageLayoutWrapper>
    </>
  );
};

export default Privacy;
