import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";
import { getProductsEndpoint } from "../endpoints";
import getLocaleTimeStamp, { TimeStamp } from "../../utils/timeStamps";
import { itemsPerPage } from "../../appConfig";
import storeDispatch from "../../state/actions/storeDispatch";
import getCatalogSearchAttributesData from "./getCatalogSearchAttributesData";
import { getProductsFilters } from "./getProductsFilters";
import setUserLists from "../../state/actions/setUserLists";

interface ProductDataRequest {
  cancelTokenSource: CancelTokenSource;
  deliveryDates: string | string[];
  categoryKey?: string;
  page?: number;
  pageSize?: number;
  query?: string;
  requestFilters?: Record<string, any>;
  sortBy?: string;
  sortDirection?: string;
  sku?: string;
}

interface ProductsData {
  products: any[];
  productsLength: number;
  pagination: any;
  filters: any;
  timeStamp: TimeStamp;
  version: string;
}

/**
 * constructs an object which holds all ProductData
 * @param {CancelTokenSource} cancelTokenSource
 * @param {string | string[]} deliveryDates
 * @param {string} categoryKey
 * @param {number} page
 * @param {number} pageSize
 * @param {string} query
 * @param {Record<string, any>} requestFilters
 * @param {string} sortBy
 * @param {string} sortDirection
 * @return {ProductsData}
 */
const getProductsData = ({
  cancelTokenSource,
  deliveryDates,
  categoryKey,
  page = 0,
  pageSize = itemsPerPage.products,
  query,
  requestFilters,
  sortBy,
  sortDirection,
  sku,
}: ProductDataRequest): Promise<void | ProductsData> => {
  if (!deliveryDates) {
    Promise.reject(new Error("No deliveryDate present."));
  }

  const productGetUrl = getProductsEndpoint({
    page,
    pageSize,
    sortBy,
    sortDirection,
    categoryKey,
    requestFilters,
    deliveryDates,
    query,
    sku,
  });

  const productsForDates = axiosWithAuth().get(productGetUrl, {
    cancelToken: cancelTokenSource.token,
  });

  return Promise.all([
    productsForDates,
    setUserLists({ cancelTokenSource }),
  ]).then(([productsEndpointResponse]) => {
    if (!productsEndpointResponse) {
      return Promise.reject(productsEndpointResponse);
    }

    const {
      data: { data },
    } = productsEndpointResponse;

    // get first attributes set of data programmatically
    const attributes = getCatalogSearchAttributesData(data);

    const {
      attributeNames,
      concreteProducts,
      pagination,
      rangeFacets,
      slots,
      valueFacets,
      orderItemIndexVersion: version,
    } = attributes;

    // store attribute names to redux
    storeDispatch("products/set-attribute-names", attributeNames);

    const filterData = getProductsFilters(valueFacets, rangeFacets, slots);

    // write new filters to Redux
    storeDispatch("filter/set-product-filter", filterData);

    return {
      products: concreteProducts,
      productsLength: pagination?.numFound || 0,
      pagination,
      filters: filterData,
      ...getLocaleTimeStamp(),
      version,
    };
  });
};

export default getProductsData;
