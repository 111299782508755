import React, { useRef, useState } from "react";

import clsx from "clsx";
import { useForm } from "antd/lib/form/Form";
import {
  Button,
  Checkbox,
  Form,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios, { CancelTokenSource } from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { getValidDeliveryDate } from "../../../../utils/datePicker";
import requestCatchHandler from "../../../../api/requestCatchHandler";
import postQuickOrderFile from "../../../../api/quickOrder/postQuickOrderFile";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../../api/getCancelTokenSource";
import useCancelAxiosOnUnmount from "../../../../hooks/useCancelAxiosOnUnmount";
import updateCartItemQuantityMulti from "../../../../state/actions/updateCartItemQuantityMulti";
import {
  locationSearchQueryParameter,
  messageData,
  routePathNames,
} from "../../../../appConfig";
import { apiDateFormat, fullDateFormat } from "../../../../utils/dateFormats";
import { FileFormat, QuickOrderItem } from "../../../../types/quickOrderFile";

interface QuickOrderUploadProps {
  deliveryDate: string;
  className?: string;
}

const fileExtensionMap: { [fileFormat in FileFormat]: string } = {
  palm: ".txt",
  csv: ".csv",
  bnn: ".*",
};

const QuickOrderUpload: React.FC<QuickOrderUploadProps> = (
  props: QuickOrderUploadProps
) => {
  const { deliveryDate, className } = props;

  const [form] = useForm();
  const navigate = useNavigate();

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const [fileFormat, setFileFormat] = useState<FileFormat>("palm");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [items, setItems] = useState<Array<QuickOrderItem>>([]);
  const [unknownItems, setUnknownItems] = useState<Array<QuickOrderItem>>([]);
  const [shouldOverwriteDeliveryDate, setShouldOverwriteDeliveryDate] =
    useState<boolean>(true);
  const [modalRequestDeliveryDate, setModalRequestDeliveryDate] =
    useState<string>(null);
  const [modalValidDeliveryDate, setModalValidDeliveryDate] =
    useState<string>(null);

  const handleUpload = async (file: File) => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    setIsUploading(true);

    let data = null;

    try {
      data = await postQuickOrderFile({
        file,
        format: fileFormat,
        cancelTokenSource: cancelTokenSource.current,
        deliveryDate,
        overwriteDeliveryDate:
          shouldOverwriteDeliveryDate && fileFormat === "bnn",
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsUploading(false);
        message.error(messageData.error.quickOrder.fileUploadError);
      }
      requestCatchHandler(error);
      return;
    }

    setIsUploading(false);

    setItems(data?.items || []);
    setUnknownItems(data?.unknownItems || []);

    if (!data?.items?.length && !data?.unknownItems?.length) {
      message.info(messageData.info.quickOrder.fileNoItems);
      return;
    }

    const requestDeliveryDate = data?.deliveryDate || deliveryDate;

    setModalRequestDeliveryDate(requestDeliveryDate);

    // Make sure we only use valid delivery date
    const validDeliveryDate =
      requestDeliveryDate !== deliveryDate
        ? getValidDeliveryDate(moment(requestDeliveryDate)).format(
            apiDateFormat
          )
        : requestDeliveryDate;

    setModalValidDeliveryDate(validDeliveryDate);

    if (data?.items?.length) {
      setIsAdding(true);

      cancelTokenSource.current = cancelAndRenewCancelToken(
        cancelTokenSource.current
      );

      try {
        await updateCartItemQuantityMulti({
          deliveryDate: validDeliveryDate,
          items: data?.items?.map((quickOrderItem: QuickOrderItem) => {
            return {
              sku: quickOrderItem.productId,
              quantity: quickOrderItem.quantity,
            };
          }),
          cancelTokenSource: cancelTokenSource.current,
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          setIsAdding(false);
          message.error(messageData.error.quickOrder.fileAddItems);
        }
        requestCatchHandler(error);
        return;
      }

      setIsAdding(false);
    }

    if (data?.unknownItems?.length > 0) {
      setIsModalVisible(true);
      return;
    }

    if (requestDeliveryDate !== validDeliveryDate) {
      message.success({
        ...messageData.success.quickOrder.fileNewDeliveryDateSuccess,
        content:
          messageData.success.quickOrder.fileNewDeliveryDateSuccess.content.replace(
            "{{deliveryDate}}",
            moment(validDeliveryDate).format(fullDateFormat)
          ),
      });
      return;
    }

    message.success({
      ...messageData.success.quickOrder.fileSuccess,
      content: messageData.success.quickOrder.fileSuccess.content.replace(
        "{{deliveryDate}}",
        moment(requestDeliveryDate).format(fullDateFormat)
      ),
    });
  };

  return (
    <div className={clsx("quick-order-upload", className)}>
      <Form
        form={form}
        initialValues={{
          format: fileFormat,
          shouldOverwriteDeliveryDate,
        }}
      >
        <Form.Item label="Format" name="format">
          <Radio.Group
            onChange={(e: RadioChangeEvent) => {
              setFileFormat(e.target.value as FileFormat);
            }}
          >
            <Radio.Button value="palm">Palm</Radio.Button>
            <Radio.Button value="csv">CSV</Radio.Button>
            <Radio.Button value="bnn">BNN</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {fileFormat === "bnn" && (
          <Form.Item name="shouldOverwriteDeliveryDate">
            <Checkbox
              onChange={() => {
                setShouldOverwriteDeliveryDate(!shouldOverwriteDeliveryDate);
              }}
              checked={shouldOverwriteDeliveryDate}
            >
              Lieferdatum aus Bestelldatei übernehmen
            </Checkbox>
          </Form.Item>
        )}
        <Form.Item name="upload">
          <Space direction="vertical">
            <Upload
              customRequest={(requestOption) => {
                handleUpload(requestOption.file as File).then();
              }}
              maxCount={1}
              showUploadList={false}
              accept={fileExtensionMap[fileFormat]}
            >
              <Button
                icon={<UploadOutlined />}
                disabled={!fileFormat}
                loading={isUploading || isAdding}
              >
                Datei hochladen
              </Button>
            </Upload>
            {fileFormat === "csv" && (
              <a
                href={`${process.env.PUBLIC_URL}/vorlage_schnellbestellung.csv`}
                className="disabled"
                download
              >
                csv Vorlage herunterladen
              </a>
            )}
          </Space>
        </Form.Item>
      </Form>
      <Modal
        visible={isModalVisible}
        title="Unbekannte Artikel"
        width={800}
        closable
        maskClosable={false}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Schließen"
        onOk={() => {
          navigate(
            `${routePathNames.cart}?${locationSearchQueryParameter.deliveryDate}=${modalValidDeliveryDate}`
          );
        }}
        okText="Zum Warenkorb"
        okButtonProps={{
          style: { display: items?.length > 0 ? "Inline" : "None" },
        }}
        destroyOnClose
      >
        <p>
          Es wurden {unknownItems?.length || 0} /{" "}
          {(unknownItems?.length || 0) + (items?.length || 0)} Artikel nicht
          erkannt.
        </p>

        <p>Unbekannte Artikel:</p>
        <ul>
          {unknownItems?.map((unknownItem: QuickOrderItem, i: number) => (
            <li key={`unknown_item_${unknownItem.productId}_${i}`}>
              {`${unknownItem.quantity}x ${unknownItem.productId}`}
            </li>
          ))}
        </ul>

        {items?.length > 0 &&
          modalRequestDeliveryDate === modalValidDeliveryDate && (
            <p>
              Die bekannten Artikel wurden erfolgreich zum Warenkorb für den{" "}
              <strong>
                {moment(modalRequestDeliveryDate).format(fullDateFormat)}
              </strong>{" "}
              hinzugefügt.
            </p>
          )}

        {items?.length > 0 &&
          modalRequestDeliveryDate !== modalValidDeliveryDate && (
            <p>
              Da das Lieferdatum in der Datei ungültig ist, wurde das
              nächstmögliche Lieferdatum gewählt. Die bekannten Artikel wurden
              erfolgreich zum Warenkorb für den{" "}
              <strong>
                {moment(modalValidDeliveryDate).format(fullDateFormat)}
              </strong>{" "}
              hinzugefügt.
            </p>
          )}
      </Modal>
    </div>
  );
};

export default QuickOrderUpload;
