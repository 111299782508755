import { CancelTokenSource } from "axios";
import axiosWithAuth from "../axiosWithAuth";

import { productDetailsEndpoint } from "../endpoints";
import { ProductDetails } from "../../types/productData";

interface GetProductDetailsProps {
  sku: string;
  cancelTokenSource: CancelTokenSource;
}

const getProductDetails = async (props: GetProductDetailsProps) => {
  const { sku, cancelTokenSource } = props;

  const url = `${productDetailsEndpoint}?sku=${sku}`;

  return axiosWithAuth()
    .get(url, { cancelToken: cancelTokenSource.token })
    .then((response): Promise<any> | ProductDetails => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }

      return (response?.data?.data?.[0]?.attributes || {}) as ProductDetails;
    });
};

export default getProductDetails;
