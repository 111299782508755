import { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { Entry } from "contentful";

import handleError from "../utils/handleError";
import getAdProductListing from "../api/contentful/getAdProductListing";
import { StockType } from "../types/stock";
import { ContentfulAdProductListingEntry } from "../types/contentfulAdProductListingEntry";

interface GetAdProductListingProps {
  categoryIds: string;
  stockName: StockType;
}

const useGetAdProductListing = (props: GetAdProductListingProps) => {
  const { categoryIds, stockName } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [entries, setEntries] = useState<
    Entry<ContentfulAdProductListingEntry>[]
  >([]);
  const [total, setTotal] = useState<number>(null);

  const fetchAdProductListing = useCallback(async () => {
    if (!categoryIds) {
      return;
    }

    setIsLoading(true);

    getAdProductListing({
      categoryIds,
      stockName,
    })
      .then((response) => {
        const categoryIdsForSorting = categoryIds.split(",");
        const items =
          response?.items?.sort(
            (
              a: Entry<ContentfulAdProductListingEntry>,
              b: Entry<ContentfulAdProductListingEntry>
            ) => {
              return (
                categoryIdsForSorting.indexOf(a?.fields?.category) -
                categoryIdsForSorting.indexOf(b?.fields?.category)
              );
            }
          ) || [];
        setEntries(items);
        setTotal(items?.length || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setHasError(true);
          setIsLoading(false);
          handleError(error);
        }
      });
  }, [categoryIds, stockName]);

  useEffect(() => {
    fetchAdProductListing().then();
  }, [fetchAdProductListing]);

  return {
    isLoading,
    hasError,
    entries,
    total,
  };
};

export default useGetAdProductListing;
