import React, { useRef, useState } from "react";

import clsx from "clsx";
import { Button, Modal } from "antd";
import axios, { CancelTokenSource } from "axios";

import getBrandInfo from "../../../api/brand/getBrandInfo";
import requestCatchHandler from "../../../api/requestCatchHandler";
import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import { BrandInfo } from "../../../types/brandInfo";

interface BandPortraitModalProps {
  brandName: string;
  brandCode: string;
}

const BrandPortraitModal: React.FC<BandPortraitModalProps> = (
  props: BandPortraitModalProps
) => {
  const { brandName, brandCode } = props;

  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());

  const [brandInfo, setBrandInfo] = useState<BrandInfo>(null);
  const [hasBrandInfo, setHasBrandInfo] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (!brandCode || !brandName) {
    return null;
  }

  return (
    <>
      <Button
        className="button-brand-portrait"
        onClick={() => {
          cancelTokenSource.current = cancelAndRenewCancelToken(
            cancelTokenSource.current
          );

          getBrandInfo({
            brandCode,
            cancelTokenSource: cancelTokenSource.current,
          })
            .then((result) => {
              if (result !== null) {
                setBrandInfo(result);
                setHasBrandInfo(true);
              }
            })
            .catch((error) => {
              if (!axios.isCancel(error)) {
                setHasBrandInfo(false);
                setBrandInfo(null);
              }

              requestCatchHandler(error);
            });

          setIsModalVisible(true);
        }}
      >
        {`Markenportrait ${brandName}`}
      </Button>
      <Modal
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        visible={isModalVisible}
        closable={false}
        okText="Schließen"
        cancelButtonProps={{ style: { display: "none" } }}
        className="brandPortraitModal"
        width={600}
        destroyOnClose
      >
        {hasBrandInfo ? (
          <div>
            <span className="brand-name">{brandName}</span>
            <div
              className={clsx(
                "brand-text",
                brandInfo?.brandLogo && "has-valid-picture"
              )}
            >
              <span>{brandInfo?.brandText || ""}</span>
              {brandInfo?.brandLogo && (
                <img src={brandInfo?.brandLogo} alt={brandName || ""} />
              )}
            </div>
          </div>
        ) : (
          <div>
            <span className="brand-name">
              Es konnten keine Daten zu {brandName} gefunden werden.
            </span>
          </div>
        )}
      </Modal>
    </>
  );
};
export default BrandPortraitModal;
