import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";
import getPageOffset from "../../utils/getPageOffset";
import { itemsPerPage } from "../../appConfig";
import { stocktakingEndpoint, stocktakingItemEndpoint } from "../endpoints";
import {
  GetStocktakingItemsAttributesResponse,
  StocktakingItemsSorting,
} from "../../types/stocktaking";

interface GetStocktakingItemsProps {
  stocktakingId: number;
  limit?: number;
  page?: number;
  sorting?: StocktakingItemsSorting;
  cancelTokenSource: CancelTokenSource;
}

const getStocktakingItems = async (props: GetStocktakingItemsProps) => {
  const {
    stocktakingId,
    limit = itemsPerPage.stocktakingItems,
    page = 1,
    sorting = "-updatedAt",
    cancelTokenSource,
  } = props;

  const url = `${stocktakingEndpoint}/${stocktakingId}${stocktakingItemEndpoint}?page[limit]=${limit}&page[offset]=${getPageOffset(
    page,
    limit
  )}&sort=${sorting}`;

  return axiosWithAuth()
    .get(url, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response): Promise<any> | GetStocktakingItemsAttributesResponse => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }
      const { attributes } = response?.data?.data?.[0] || {};

      return attributes as GetStocktakingItemsAttributesResponse;
    });
};

export default getStocktakingItems;
