import React from "react";

import clsx from "clsx";
import { Button } from "antd";
import { useDispatch } from "react-redux";

import QuickOrderProductTile from "./QuickOrderProductTile";
import QuickOrderProductPrices from "./QuickOrderProductPrices";
import QuickOrderProductAlternatives from "./QuickOrderProductAlternatives";
import { QuickOrderStateItem } from "../../../../../types/quickOrder";
import { ReactComponent as DeleteIcon } from "../../../../../static/svg/delete.svg";

interface QuickOrderItemProps {
  deliveryDate: string;
  item: QuickOrderStateItem;
  isPreview?: boolean;
  className?: string;
}

const QuickOrderItem: React.FC<QuickOrderItemProps> = (
  props: QuickOrderItemProps
) => {
  const { deliveryDate, item = null, isPreview = false, className } = props;

  const dispatch = useDispatch();

  const deleteItemFromQuickOrderItems = () => {
    dispatch({
      type: "quick-order/delete-item",
      payload: {
        item,
      },
    });
  };

  if (!item?.product || !item?.product?.sku) {
    return null;
  }

  return (
    <div className={clsx("quick-order-item", className)}>
      <QuickOrderProductTile
        deliveryDate={deliveryDate}
        productData={item.product}
      />

      <QuickOrderProductAlternatives
        deliveryDate={deliveryDate}
        productData={item.product}
      />

      <QuickOrderProductPrices
        deliveryDate={deliveryDate}
        productData={item.product}
      />

      {!isPreview && (
        <div className="quick-order-item-info">
          <div>{item?.quantity || 0}</div>

          <Button
            type="text"
            icon={<DeleteIcon />}
            onClick={deleteItemFromQuickOrderItems}
          />
        </div>
      )}
    </div>
  );
};

export default QuickOrderItem;
