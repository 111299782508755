import React from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";
import { Tag } from "antd";

import useSetCategoryNavigationVisibility from "../../hooks/useSetCategoryNavigationVisibility";
import isNewCategory from "../../utils/isNewCategory";
import { Category } from "../../types/category";

interface NavNodeProps {
  title: string;
  subTitle: boolean;
  className?: string;
  route?: string;
  category?: Category | any;
}

const NavNode: React.FC<NavNodeProps> = (props: NavNodeProps) => {
  const { title, subTitle, className, route, category } = props;

  const setCategoryNavigationVisibility = useSetCategoryNavigationVisibility();

  const isNew = isNewCategory(category?.categoryCreatedAt);

  return (
    <span className={clsx("nav-node", isNew && "nav-node--is-new")}>
      {subTitle ? (
        <>
          <span className="categoryTitle">{title}</span>
          <span>Übersicht</span>
        </>
      ) : (
        <>
          <Link
            to={route}
            className={clsx("categoryTitle", className)}
            onClick={() => {
              setCategoryNavigationVisibility(false);
            }}
          >
            {title}
          </Link>
          {isNew && (
            <div>
              <Tag>Neu</Tag>
            </div>
          )}
        </>
      )}
    </span>
  );
};

export default NavNode;
