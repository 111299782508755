import moment from "moment";

function isDeliveryDateNextMonth(deliveryDate: string) {
  const currentDate = moment();

  const startOfNextMonth = currentDate.clone().add(1, "month").startOf("month");
  const endOfNextMonth = currentDate.clone().add(1, "month").endOf("month");

  return moment(deliveryDate).isBetween(
    startOfNextMonth,
    endOfNextMonth,
    "day",
    "[]"
  );
}

export default isDeliveryDateNextMonth;
