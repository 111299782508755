import { Button, Calendar, DatePicker } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import disabledDatePickerDates from "../../../utils/disabledDatePickerDates";
import disabledDatesAfter from "../../../utils/disabledDatesAfter";
import ProductPreorderModalInnerCalenderCellTooltip from "./ProductPreorderModalInnerCalenderCellTooltip";
import ProductPreorderModalInnerInfo from "./ProductPreorderModalInnerInfo";
import { routePathNames } from "../../../appConfig";
import useGetAllCartsCheapMetadata from "../../../hooks/useGetAllCartsCheapMetadata";
import { ProductData } from "../../../types/productData";
import { apiDateFormat, fullDateFormat } from "../../../utils/dateFormats";
import { RootState } from "../../../types/rootState";

interface Props {
  wasAddedToCart: boolean;
  inCartErrorMsg: string;
  sku: ProductData["sku"];
  nextAvailability: any;
  datePickerDate: moment.Moment;
  lastOrderDate: any;
  setDatePickerDate: (param: moment.Moment) => void;
  setIsDatePickerModalOpen: (param: boolean) => void;
  setWasAddedToCart: (param: any) => void;
  setCurrentSelectedAmount: (param: any) => void;
  inputHasChanged: boolean;
  addToCart: (param: string) => void;
}

const ProductPreorderModalInner = function ProductPreorderModalInner({
  wasAddedToCart,
  inCartErrorMsg,
  sku,
  nextAvailability,
  datePickerDate,
  lastOrderDate,
  setDatePickerDate,
  setIsDatePickerModalOpen,
  setWasAddedToCart,
  setCurrentSelectedAmount,
  inputHasChanged,
  addToCart,
}: Props) {
  const { openCarts } = useGetAllCartsCheapMetadata();

  const orderDateInfo = useSelector(
    (state: RootState) => state?.alreadyOrdered?.orderItemIndex?.[sku]
  );

  return (
    <>
      {!wasAddedToCart && (
        <>
          <div>
            Der {moment(nextAvailability).format(fullDateFormat)} ist das
            nächstmögliche Lieferdatum. Bitte wählen Sie bei Bedarf einen
            alternativen Liefertag aus:
          </div>
          <DatePicker
            suffixIcon={<Calendar />}
            defaultPickerValue={datePickerDate}
            value={datePickerDate}
            disabledDate={(date) =>
              disabledDatePickerDates(date) ||
              disabledDatesAfter(date, moment(lastOrderDate)) ||
              date.isBefore(moment(nextAvailability))
            }
            picker="week"
            inputReadOnly
            tabIndex={-1}
            size="small"
            allowClear={false}
            format={fullDateFormat}
            dropdownClassName="datePickerCalendar"
            onChange={(date, dateString) => {
              setDatePickerDate(moment(dateString, fullDateFormat));
            }}
            open
            getPopupContainer={() =>
              document.getElementById(
                "product-basket-preorder-modal-date-picker"
              )
            }
            dateRender={(current) => {
              return (
                <ProductPreorderModalInnerCalenderCellTooltip
                  sku={sku}
                  cellDate={current}
                  openCarts={openCarts}
                  orders={orderDateInfo}
                />
              );
            }}
          />
          <p id="product-basket-preorder-modal-date-picker" />
          <div className="product-basket-preorder-modal__footer">
            <Button
              className="button buttonText mr-m"
              onClick={() => {
                setIsDatePickerModalOpen(false);
                setWasAddedToCart(false);
                setCurrentSelectedAmount(0);
              }}
            >
              Abbrechen
            </Button>
            <Button
              className="button buttonPrimary"
              loading={inputHasChanged}
              disabled={!datePickerDate.isValid()}
              onClick={() => {
                addToCart(datePickerDate.format(apiDateFormat));
              }}
            >
              In den Warenkorb
            </Button>
          </div>
          <ProductPreorderModalInnerInfo
            sku={sku}
            openCarts={openCarts}
            orders={orderDateInfo}
          />
        </>
      )}
      {wasAddedToCart && !inCartErrorMsg && (
        <>
          <p>
            Der Artikel wurde dem Warenkorb zum{" "}
            {datePickerDate.format(fullDateFormat)} hinzugefügt.
          </p>
          <p>
            Bitte vervollständigen Sie diesen Warenkorb und schicken ihn
            rechtzeitig ab.
          </p>
          <div className="product-basket-preorder-modal__footer">
            <a
              href={`${
                routePathNames.cart
              }?deliveryDate=${datePickerDate.format(apiDateFormat)}`}
              className="button buttonText mr-m"
              role="button"
            >
              Zum Warenkorb
            </a>
            <Button
              className="button buttonPrimary"
              onClick={() => {
                setIsDatePickerModalOpen(false);
                setWasAddedToCart(false);
              }}
            >
              Weiter bestellen
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ProductPreorderModalInner;
