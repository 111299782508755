import React, { useMemo } from "react";

import clsx from "clsx";
import { useSelector } from "react-redux";
import moment from "moment/moment";

import getProductUVP from "../../../utils/getProductUVP";
import CartCheckout from "../../cart/CartCheckout";
import Price from "../../price/Price";
import { fullDateFormat } from "../../../utils/dateFormats";
import { PrintViewTableDataProps } from "../../../types/printViewTableData";
import { RootState } from "../../../types/rootState";
import { ReactComponent as WeilingLogo } from "../../../static/svg/weiling.svg";
import { ReactComponent as UserIcon } from "../../../static/svg/account.svg";
import { ReactComponent as TruckIcon } from "../../../static/svg/neuprodukt_lieferant.svg";
import { ReactComponent as EditIcon } from "../../../static/svg/edit.svg";
import { ReactComponent as SupportAltIcon } from "../../../static/svg/support-alternative.svg";

interface PrintViewProps {
  className?: string;
  printLayout: "cart" | "order";
  deliveryDate: string;
  orderDate?: string;
  cartNote: string;
  checkout?: JSX.Element;
  printViewTableData?: PrintViewTableDataProps[];
}

const PrintView: React.FC<PrintViewProps> = (props: PrintViewProps) => {
  const {
    className = "",
    printLayout = "order",
    deliveryDate = "",
    orderDate = "",
    cartNote = "",
    checkout = null,
    printViewTableData = [],
  } = props;

  const { businessUnit } = useSelector(
    (state: RootState) => state?.userData || {}
  );

  const itemsSumPrice = useMemo(
    () =>
      printViewTableData
        ?.map((item: any) => item.sumPrice || 0)
        ?.reduce((sum: number, cur: number) => sum + cur, 0),
    [printViewTableData]
  );

  return (
    <div className={clsx("print-view", "show-print", className)}>
      <span className="print-view__banner">
        <WeilingLogo />
      </span>
      <div className="print-view__content">
        {printLayout === "order" ? (
          <h3>
            Ihre Bestellung zum {moment(deliveryDate).format(fullDateFormat)}
          </h3>
        ) : (
          <h3>
            Ihre Bestellung zum {moment(deliveryDate).format(fullDateFormat)}{" "}
            vom Warenkorb
          </h3>
        )}
        <div className="print-view__content__divider" />
        <div className="print-view__content__order">
          <div className="print-view__content__order__order-info">
            <div className="print-view__content__order__order-info__dates">
              <span
                className={clsx(printLayout === "cart" && "cart-delivery-date")}
              >
                <TruckIcon />
                <span>
                  Lieferdatum
                  <br />
                  {moment(deliveryDate).format(fullDateFormat)}
                </span>
              </span>
              {printLayout === "order" && (
                <span>
                  <TruckIcon />
                  <span>
                    Bestelldatum
                    <br />
                    {moment(orderDate).format(fullDateFormat)}
                  </span>
                </span>
              )}
            </div>
            {printLayout === "order" ? (
              <CartCheckout
                totalSelectedItems={printViewTableData?.length || 0}
                selectedItemsTotal={itemsSumPrice}
                isAlwaysVisible
                confirmationPlacement="top"
              />
            ) : (
              checkout
            )}
          </div>
          <div className="print-view__content__order__customer-info">
            <span className="print-view__content__order__customer-info__address">
              <UserIcon />
              &nbsp;Lieferadresse
            </span>
            <br />
            <span>Kundennummer: {businessUnit?.companyBusinessUnitKey}</span>
            <br />
            <br />
            <span>{businessUnit?.companyName}</span>

            <br />
            <span>{businessUnit?.address1}</span>
            <br />
            <span>
              {businessUnit?.zipCode} {businessUnit?.city}
            </span>
          </div>
        </div>
        <div className="print-view__content__product-table">
          <table>
            <thead>
              <tr>
                <th>Nr</th>
                <th>Artikelbezeichnung</th>
                <th>Anzahl</th>
                <th>VPE</th>
                <th>UVP</th>
                <th>Einzelpreis</th>
                <th>Gesamtpreis</th>
              </tr>
            </thead>
            <tbody>
              {printViewTableData?.map((item) => {
                const productUVP = getProductUVP(
                  item?.rrPrice || 0,
                  item?.itemBaseUnit || 1,
                  item?.weighingArticle === "1"
                );

                return (
                  <tr key={`print-${item?.sku}`}>
                    <td>{item?.sku}</td>
                    <td>{item?.description}</td>
                    <td>{item?.quantity}</td>
                    <td>
                      {item.itemBaseUnit} x {item?.itemVpe}
                    </td>
                    <td>{!productUVP ? "-" : <Price price={productUVP} />}</td>
                    <td>
                      <Price price={item?.unitPrice} />
                    </td>
                    <td>
                      <Price price={item?.sumPrice} />
                    </td>
                  </tr>
                );
              })}
              <tr key="print-sumPrice">
                <td colSpan={5}>Gesamtsumme</td>
                <td>
                  <Price price={itemsSumPrice} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="print-view__content__divider" />
        <div className="print-view__content__customer-note">
          <EditIcon className="print-view__content__customer-note__edit-icon" />
          <span>
            IHRE PERSÖNLICHE NOTIZ
            <br />
            {cartNote}
          </span>
        </div>

        <div className="print-view__content__support">
          <SupportAltIcon className="print-view__content__support__support-icon" />
          <span>
            SIE HABEN FRAGEN ZU IHRER BESTELLUNG?
            <br />
            Telefon: 02541 / 747-1410
          </span>
        </div>
        <span className="print-view__content__greeting">
          Mit freundlichen Grüßen,
          <br />
          Ihr Team vom Weiling-Webshop
        </span>
      </div>
      <span className="print-view__banner">
        <WeilingLogo />
      </span>
    </div>
  );
};

export default PrintView;
