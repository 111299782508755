import React from "react";

import clsx from "clsx";

import { Link } from "react-router-dom";
import { Col, Layout, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";

import BackButton from "../../backButton/BackButton";
import isExternalUrl from "../../../utils/isExternalUrl";
import getRelativeFromAbsoluteUrl from "../../../utils/getRelativeFromAbsoluteUrl";

interface ContentBlockProps {
  icon?: React.ReactNode;
  title?: string;
  linkText?: string;
  linkHref?: string;
  linkClickCallback?: () => void;
  showLink?: boolean;
  showBackButton?: boolean;
  showHeader?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const ContentBlock: React.FC<ContentBlockProps> = (props) => {
  const {
    icon = null,
    title = "",
    linkText = "Alle anzeigen",
    linkHref,
    linkClickCallback,
    showLink = false,
    showBackButton = false,
    showHeader = true,
    children,
    className,
  } = props;

  return (
    <Layout
      className={clsx(
        "container-layout container-layout--inner mb-xl mt-xl content-block",
        className
      )}
    >
      {!!icon && (
        <Col span={12} className="content-block__icon">
          {icon}
        </Col>
      )}
      {showBackButton && <BackButton />}
      {showHeader && (
        <Row>
          <Col span={12} className={clsx("content-block__header")}>
            <h3
              className={clsx(
                "content-block__title",
                !showLink && "content-block__title-noLink"
              )}
            >
              {title}
            </h3>
            {showLink &&
              linkHref &&
              (!isExternalUrl(linkHref) ? (
                <span className={clsx("content-block__title-link")}>
                  <Link
                    to={getRelativeFromAbsoluteUrl(linkHref)}
                    onClick={linkClickCallback}
                  >
                    {linkText}
                    <RightOutlined />
                  </Link>
                </span>
              ) : (
                <span className={clsx("content-block__title-link")}>
                  <a
                    href={linkHref}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={linkClickCallback}
                  >
                    {linkText}
                    <RightOutlined />
                  </a>
                </span>
              ))}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12} className={clsx("content-block__content")}>
          {children}
        </Col>
      </Row>
    </Layout>
  );
};

export default ContentBlock;
