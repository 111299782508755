import React, { useState } from "react";

import clsx from "clsx";
import { areEqual } from "react-window";
import { useDispatch, useSelector } from "react-redux";
import { Button, Space } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import useGetSelectedFiltersCount from "../../../../hooks/useGetSelectedFiltersCount";
import useShouldWeekplannerBeVisible from "../../../../hooks/useShouldWeekplannerBeVisible";
import getCssVariable from "../../../../utils/getCssVariable";
import useMedia from "../../../../hooks/useMedia";
import BackButton from "../../../backButton/BackButton";
import Breadcrumbs from "../../../breadcrumbs/Breadcrumbs";
import HeaderButtons from "../../../products/HeaderButtons";
import ReorderModal from "../../../order/OrderDetail/ReorderModal";
import WeekplannerHeaderNavigation from "./WeekplannerHeaderNavigation";
import { FiltersDesktop } from "../../../organisms";
import {
  BrandPortraitModal,
  FilterBar,
  SelectedFilters,
  WebshopCategoryDownloads,
  WebshopCategoryVideos,
} from "../../../molecules";
import {
  routePathNames,
  routeProductCategoryKeys,
} from "../../../../appConfig";
import { ProductData } from "../../../../types/productData";
import { RootState } from "../../../../types/rootState";
import { ReactComponent as CalendarIcon } from "../../../../static/svg/deliverydate.svg";

interface WeekplannerHeaderProps {
  categoryKey: string;
  productItems: Array<ProductData>;
  paginationData: Record<string, any>;
  isLoading: boolean;
  sortBy?: string;
  sortDirection?: string;
  searchQuery?: string;
  isExpanded?: boolean;
  className?: string;
}

const WeekplannerHeader: React.FC<WeekplannerHeaderProps> = (
  props: WeekplannerHeaderProps
) => {
  const {
    categoryKey = "1",
    productItems,
    paginationData,
    isLoading,
    sortBy,
    sortDirection,
    searchQuery,
    isExpanded = true,
    className,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedFiltersCount } = useGetSelectedFiltersCount();
  const shouldWeekplannerBeVisible = useShouldWeekplannerBeVisible();

  const { id, weekplannerUseSimpleMode: useSimpleMode = false } = useSelector(
    (state: RootState) => state?.userData || {}
  );
  const { herstellermarkenpreislistentextfuerausgabe: brandName } = useSelector(
    (state: RootState) => state.productsMetaData?.filters?.filterStates
  );
  const browserIsDesktop = useMedia(
    `(min-width: ${getCssVariable("screen-md")})`
  );

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [isReorderModalVisible, setIsReorderModalVisible] =
    useState<boolean>(false);

  const brandCode = productItems[0]?.attributes?.herstellermarken_text;
  const isMonthlyCampaign = !!(
    categoryKey === routeProductCategoryKeys.currentPromotion ||
    categoryKey === routeProductCategoryKeys.nextPromotion
  );

  return (
    <div
      className={clsx(
        "weekplanner-header-wrapper",
        !isExpanded && "weekplanner-header-wrapper--is-closed",
        className
      )}
    >
      <div className="weekplanner-header">
        <div className="weekplanner-header__top">
          <div className="weekplanner-header__top__left">
            <BackButton withBreadcrumbs />
            <Breadcrumbs />
          </div>
          {shouldWeekplannerBeVisible && (
            <div className="weekplanner-header__top__right">
              <Space>
                <Button
                  type="text"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    dispatch({
                      type: "user/update-user",
                      payload: {
                        weekplannerUseSimpleMode: !useSimpleMode,
                      },
                    });
                  }}
                  size="small"
                >
                  {`${useSimpleMode ? "Standard" : "Klassische"} Ansicht`}
                </Button>
                <Button
                  type="text"
                  icon={<CalendarIcon />}
                  onClick={() =>
                    navigate(`${routePathNames.userProfileEdit}${id}`)
                  }
                  size="small"
                >
                  Liefertage ändern
                </Button>
              </Space>
            </div>
          )}
        </div>

        <HeaderButtons
          paginationData={paginationData}
          categoryKey={categoryKey}
          sortBy={sortBy}
          sortDirection={sortDirection}
          searchQuery={searchQuery}
          className="weekplanner-header__title"
          showDivider={false}
        />

        {shouldWeekplannerBeVisible && (
          <div className="weekplanner-header__bottom">
            <WeekplannerHeaderNavigation
              currentCategoryKey={categoryKey}
              isLoading={isLoading}
            />

            <div className="weekplanner-header__bottom__right">
              {isMonthlyCampaign && (
                <Button
                  className="button buttonPrimary buttonAddProductsToNewCart"
                  disabled={false}
                  onClick={() => setIsReorderModalVisible(true)}
                >
                  Alles in den Warenkorb
                </Button>
              )}
              <WebshopCategoryDownloads categoryKey={categoryKey} />
              <WebshopCategoryVideos categoryKey={categoryKey} />
            </div>
          </div>
        )}

        {shouldWeekplannerBeVisible && (
          <div className="weekplanner-header__filter">
            <FilterBar
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              showSearchFilter
              selectedFiltersCount={selectedFiltersCount}
              view="weekplanner"
            />
            <FiltersDesktop
              showFilters={showFilters}
              hideFilters={() => setShowFilters(false)}
              loading={isLoading}
              display="weekplanner"
            />
            <div className="filter-product-list-top">
              <SelectedFilters />

              {brandName &&
                brandName.length === 1 &&
                brandCode &&
                browserIsDesktop && (
                  <BrandPortraitModal
                    brandName={brandName?.[0] || ""}
                    brandCode={brandCode}
                  />
                )}
            </div>
          </div>
        )}

        <ReorderModal
          productItems={productItems}
          isModalVisible={isReorderModalVisible}
          setIsModalVisible={setIsReorderModalVisible}
          modalQuestion="Möchten Sie diese Artikel dem Warenkorb hinzufügen?"
        />
      </div>
    </div>
  );
};

export default React.memo(WeekplannerHeader, areEqual);
