/*!
 * collection of callback functions for Array.prototype.sort()
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort}
 */

interface SortArrayOfObjectsByObjectKey {
  a: Record<string, any>;
  b: Record<string, any>;
  objectKey: string;
  sortOrder?: "asc" | "desc";
}

/**
 * sort the current array based on the order of another
 * @param a {*} - the first element of sort
 * @param b {*} - the second element of sort
 * @param referenceArray {Array<any>} - the array whose order is used to order this one
 */
const sortArrayByOrderOfArray = (a: any, b: any, referenceArray: any[]) =>
  referenceArray.indexOf(a) - referenceArray.indexOf(b);

/**
 * Sort the current array based on the order of another, put not found elements at last position
 * @param a {*} - the first element of sort
 * @param b {*} - the second element of sort
 * @param referenceArray {Array<any>} - the array whose order is used to order this one
 */
const sortArrayByOrderOfArrayWithNotFoundLast = (
  a: any,
  b: any,
  referenceArray: any[]
) => {
  const indexA = referenceArray.indexOf(a);
  const indexB = referenceArray.indexOf(b);

  if (indexA === -1) {
    return 1;
  }

  if (indexB === -1) {
    return -1;
  }

  return indexA - indexB;
};

/**
 * Sort the current array based on the object key order of another, put not found elements at last position
 * @param a {*} - the first element of sort
 * @param b {*} - the second element of sort
 * @param objectKey {string} - the object key to use for sorting
 * @param referenceArray {Array<any>} - the array whose order is used to order this one
 */
const sortArrayOfObjectsByObjectKeyOrderOfArrayWithNotFoundLast = (
  a: any,
  b: any,
  objectKey: string,
  referenceArray: any[]
) => {
  const indexA = referenceArray.indexOf(a?.[objectKey]);
  const indexB = referenceArray.indexOf(b?.[objectKey]);

  if (indexA === -1) {
    return 1;
  }

  if (indexB === -1) {
    return -1;
  }

  return indexA - indexB;
};

/**
 * order the current array based on the alphabetically order of a reference array
 * @param a {*} - the first element of sort
 * @param b {*} - the second element of sort
 * @param referenceArray {Array<any>} - the array that contains an order with the same index/key
 * @return {number}
 */
const sortArrayAlphabeticallyByArray = (
  a: any,
  b: any,
  referenceArray: Record<string, any>
) => {
  if (!referenceArray?.length) {
    return 0;
  }

  const nameA = String(referenceArray[a]).toLowerCase();
  const nameB = String(referenceArray[b]).toLowerCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

/**
 * sort an array of objects based on simple values
 * if the key of a or b is falsy, the item will be put to the end
 * this mimics the default behavior of Array.prototype.sort, if the value is undefined
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description}
 * @param a {Record<string, string|number|boolean>}
 * @param b {Record<string, string|number|boolean>}
 * @param objectKey {string}
 * @param sortOrder {string}
 * @return {number}
 */
const sortArrayOfObjectsByObjectKey = ({
  a,
  b,
  objectKey,
  sortOrder = "asc",
}: SortArrayOfObjectsByObjectKey) => {
  const valueA = a?.[objectKey];
  const valueB = b?.[objectKey];

  if (!valueA) {
    return 1;
  }

  if (!valueB) {
    return -1;
  }

  const returnValue =
    sortOrder === "asc" ? Number(valueA - valueB) : Number(valueB - valueA);

  // make sure to return always a number, do not sort (0), if the value is NaN
  return returnValue || 0;
};

export {
  sortArrayByOrderOfArray,
  sortArrayByOrderOfArrayWithNotFoundLast,
  sortArrayAlphabeticallyByArray,
  sortArrayOfObjectsByObjectKey,
  sortArrayOfObjectsByObjectKeyOrderOfArrayWithNotFoundLast,
};
