import { CancelTokenSource } from "axios";

import axiosWithAuth from "../axiosWithAuth";

import { locationSearchQueryParameter } from "../../appConfig";
import { quickOrderFileEndpoint } from "../endpoints";
import { FileFormat, QuickOrderFileData } from "../../types/quickOrderFile";

interface PostQuickOrderFileProps {
  format: FileFormat;
  cancelTokenSource: CancelTokenSource;
  file: File;
  deliveryDate: string;
  overwriteDeliveryDate?: boolean;
}

const postQuickOrderFile = async (
  props: PostQuickOrderFileProps
): Promise<QuickOrderFileData> => {
  const {
    format = "palm",
    file,
    cancelTokenSource,
    deliveryDate,
    overwriteDeliveryDate = false,
  } = props;

  const url = `${quickOrderFileEndpoint}?${
    locationSearchQueryParameter.fileFormat
  }=${format}&${locationSearchQueryParameter.deliveryDate}=${deliveryDate}&${
    locationSearchQueryParameter.overwriteDeliveryDate
  }=${overwriteDeliveryDate ? "1" : "0"}`;

  const form = new FormData();
  form.append("file", file);

  return axiosWithAuth()
    .post(url, form, {
      cancelToken: cancelTokenSource.token,
    })
    .then((response): Promise<any> | QuickOrderFileData => {
      if (!response?.data?.data || response.status !== 200) {
        return Promise.reject(response);
      }

      const { attributes } = response?.data?.data || {};

      return attributes as QuickOrderFileData;
    });
};

export default postQuickOrderFile;
