import React, { useContext } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ProductData } from "../../../types/productData";
import getDeliveryDateBasedAttributes from "../getDeliveryDateBasedAttributes";
import ProductBasketAvailable from "./ProductBasketAvailable";
import ProductBasketPreorder from "./ProductBasketPreorder";
import ProductBasketNextMonthCampaign from "./ProductBasketNextMonthCampaign";
import isNextMonthCampaign from "../../../utils/isNextMonthCampaign";
import ProductDetailModalContext from "../../../contexts/ProductDetailModalContext";
import isDeliveryDateNextMonth from "../../../utils/isDeliveryDateNextMonth";
import useStockForAttribute from "../../../hooks/useStockForAttribute";
import getAdvertisementText from "../../../utils/getAdvertisementText";

interface Props {
  productData: ProductData;
  deliveryDate?: ProductData["deliveryDate"];
  view?: string;
}

/**
 * ProductBasket Component
 * @param {ProductData} productData
 * @param {ProductData["deliveryDate"]} deliveryDate
 * @param {String} view
 * @constructor
 */
const ProductBasket: React.FC<Props> = ({
  productData,
  deliveryDate,
  view = "",
}: Props) => {
  const { availabilities, attributes } = productData;
  const { categoryKey } = useParams<{
    categoryKey?: string;
  }>();
  const isInProductDetailModal = useContext(ProductDetailModalContext);

  const isnextMonthlyCampaign = isNextMonthCampaign(categoryKey);

  const isdeliveryDateIsInNextMonth = isDeliveryDateNextMonth(deliveryDate);

  const { isUnavailable, nextAvailability } = getDeliveryDateBasedAttributes({
    deliveryDate,
    availabilities,
  });

  const advertisement = useStockForAttribute(attributes, "advert");

  const { from: campaignStart } = getAdvertisementText(
    advertisement,
    moment(deliveryDate),
    true,
    view
  );

  if (
    isnextMonthlyCampaign &&
    !isInProductDetailModal &&
    !isdeliveryDateIsInNextMonth &&
    moment(deliveryDate).isBefore(moment(campaignStart))
  ) {
    return (
      <ProductBasketNextMonthCampaign
        deliveryDate={deliveryDate}
        productData={productData}
        view={view}
      />
    );
  }
  // if the product is not available return no component
  if (isUnavailable && !nextAvailability) {
    return null;
  }

  // if it is not available but has next availabilities, add a modified version
  if (isUnavailable && nextAvailability) {
    const today = moment();

    // if the nextAvailability is deprecated, ignore it
    if (moment(nextAvailability).isSameOrBefore(today)) {
      return null;
    }
    return (
      <ProductBasketPreorder
        deliveryDate={deliveryDate}
        productData={productData}
      />
    );
  }

  // if it is available return the default product basket
  if (!isUnavailable) {
    return (
      <ProductBasketAvailable
        deliveryDate={deliveryDate}
        productData={productData}
      />
    );
  }

  return null;
};

export default ProductBasket;
