import React, { useMemo, useState } from "react";

import { useSelector } from "react-redux";

import { Button } from "antd";
import CartReorderModalWrapper from "./CartReorderModalWrapper";
import { RootState } from "../../../types/rootState";

const MoveCartToDate: React.FC = () => {
  const { cartItems = [], selectedCartItems: selectedSkus = [] } = useSelector(
    (state: RootState) => state?.currentCart || {}
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const selectedCartItems = useMemo(() => {
    return selectedSkus.map((sku: string) => {
      const cartItem = cartItems.find((item: any) => item?.sku === sku);
      return {
        sku,
        quantity: cartItem?.quantity || 0,
      };
    });
  }, [cartItems, selectedSkus]);

  const shouldMoveAllItems =
    cartItems?.length === selectedSkus?.length || !selectedCartItems?.length;

  return (
    <>
      <CartReorderModalWrapper
        cartItems={shouldMoveAllItems ? cartItems : selectedCartItems}
        modalVisible={isModalVisible}
        setModalVisible={setIsModalVisible}
      />

      <Button
        className="button buttonPrimary buttonPrimary--inverted buttonAddProductsToNewCart"
        disabled={!cartItems?.length || !selectedCartItems?.length}
        onClick={() => setIsModalVisible(!isModalVisible)}
      >
        Markierte Artikel verschieben
      </Button>
    </>
  );
};

export default MoveCartToDate;
